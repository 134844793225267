<div class="parking place-widget place-options-widget" *ngIf="hasOptions">
    <span class="title">Parking Options</span>
    <ul class="options">
        <li class="option" *ngIf="options?.freeGarageParking">
            <span class="material-symbols-outlined">garage</span>
            Free Garage Parking
        </li>
        <li class="option" *ngIf="options?.freeParkingLot">
            <span class="material-symbols-outlined">transportation</span>
            Free Parking Lot
        </li>
        <li class="option" *ngIf="options?.freeStreetParking">
            <span class="material-symbols-outlined">emoji_transportation</span>
            Free Street Parking
        </li>
        <li class="option" *ngIf="options?.paidGarageParking">
            <span class="material-symbols-outlined">garage</span>
            Paid Garage Parking
        </li>
        <li class="option" *ngIf="options?.paidParkingLot">
            <span class="material-symbols-outlined">transportation</span>
            Paid Parking Lot
        </li>
        <li class="option" *ngIf="options?.paidStreetParking">
            <span class="material-symbols-outlined">emoji_transportation</span>
            Paid Street Parking
        </li>
        <li class="option" *ngIf="options?.valetParking">
            <span class="material-symbols-outlined">passkey</span>
            Valet Parking
        </li>
    </ul>
</div>
