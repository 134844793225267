/** Angular material button color. Default: `basic`. */
export enum ButtonColor {
  accent = 'accent',
  basic = '',
  primary = 'primary',
  warn = 'warn',
}

/** The type of HTML element to render. Default: `button`. */
export enum ButtonElement {
  /** An anchor tag. E.g. <a></a> */
  anchor,
  /** A button element. E.g. <button></button> */
  button,
}

/** The type of button form factor to render.  */
export enum ButtonType {
  basic = 'mat-button',
  fab = 'mat-fab',
  fab_extended = 'mat-fab extended',
  flat = 'mat-flat-button',
  icon = 'mat-icon-button',
  mini_fab = 'mat-mini-fab',
  raised = 'mat-raised-button',
  stroked = 'mat-stroked-button',
}
