import { Injectable } from '@angular/core';

export interface CustomError {
  error?: {
    error?: {
      code?: string;
      details?: {msg: string; path: string; type: string; value: any}[];
      message?: string;
    },
    message?: string;
  },
  message?: string;
  status?: number;
  statusText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor() { }

  static extract(error: CustomError) {
    if(!error) {return '';}

    let code = error?.error?.error?.code ||
      error?.error?.message ||
      error?.statusText ||
      error?.status;

    let message =  error?.error?.error?.message ||
      error?.error?.message ||
      error?.message ||
      error?.statusText;

    switch(code) {
      case 'INVALID_CREDENTIALS_SUPPLIED':
        return 'An invalid email and password combination was presented.';
      case 'VALIDATION_ERROR':
        if(message && ['LOGIN_FAILED'].indexOf(message) != -1) {
          return 'An invalid email and password combination was presented.';
        }
        message = 'VALIDATION ERROR';
        message+= !error?.error?.error?.details?.length ? '' :
          ': ' + error.error.error.details.map(detail => {
            return detail.path + ': ' + detail?.msg;
          }).join('. ');
        return message;
    }
    return message?.replaceAll('_', ' ');
  }
}
