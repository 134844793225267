export const SocketEvents = {
    /** user book mark created */
    bookmarkCreated: `bookmarkCreated`,
    /** user book mark deleted */
    bookmarkDeleted: `bookmarkDeleted`,
    /** client-to-server connection failure */
    connect_error: `connect_error`,
    /** client connected */
    connected: `connected`,
    /** client connection request recieved. */
    connection: `connection`,
    /** server recieved client disconnection */
    disconnect: `disconnect`,
    /** client disconnected */
    disconnected: `disconnected`,
    /** client connection exited. */
    exit: `exit`,
    /** client authentication successful. */
    login: `login`,
    /** trip created by assistant: `trips:trip_created_by_assistant` */
    promptInterfaceServerStatusUpdate: `prompt_interface:server_status_update`,
    /** trip created by assistant: `trips:trip_created_by_assistant` */
    tripCreatedByAssistant: `trips:trip_created_by_assistant`,
};

export enum SocketErrors {
    client_credentials_expired = 'EXPIRED_CREDENTIALS_SUPPLIED',
    invalid_username = 'invalid_username',
    xhr_poll_error = 'xhr poll error',
}