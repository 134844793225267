<div class="ratings">
    <div class="filled-stars">
        <span *ngFor="let x of [].constructor(ratingFullStars); let i = index" class="material-symbols-outlined filled">
            star
        </span>
    </div>
    <div class="partial-star">
        <span class="material-symbols-outlined filled" *ngFor="let x of [].constructor(ratingPartialStars); let i = index">
            star_half
        </span>
    </div>
    <div class="empty-stars">
        <span class="material-symbols-outlined empty" *ngFor="let x of [].constructor(ratingEmptyStars); let i = index">
            star
        </span>
    </div>
    <div class="counts">
        <span>
            {{ ratingValue }} stars
        </span>
        <span>
            ({{ ratingCount + (ratingCount > 1 ? ' ratings' : ' rating') }})
        </span>
    </div>
</div>