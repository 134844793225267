import { Injectable } from '@angular/core';
import { ApplicationState, ApplicationStateKey } from '../interfaces/application-state';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * Defines the ApplicationState properties (key) that should be saved to local storage and not to session storage as is the default. 
   * Default: `['auth','viewport','session','bookmarks']`
   */
  localKeys:ApplicationStateKey[] = ['auth','viewport','session','bookmarks'];

  constructor() { }

  get(key: string) {
    let local = localStorage.getItem(key);
    let session = sessionStorage.getItem(key);
    let localObj = (!!local ? JSON.parse(local) : local) as ApplicationState;
    let sessionObj = (!!session ? JSON.parse(session) : session) as ApplicationState;
    let data = {...localObj, ...sessionObj};
    return data;
  }

  save(key: string, value: ApplicationState) {
    this.saveSession(key, value);
    this.saveLocal(key, value);
  }

  saveLocal(key: string, value: any) {
    let rehydration = localStorage.getItem(key);
    let data:any = rehydration ? JSON.parse(rehydration) : {};
    this.localKeys.forEach((_key) => {
      data[_key] = value[_key]
    });
    localStorage.setItem(key, JSON.stringify(data));
  }

  saveSession(key: string, value: any) {
    let rehydration = sessionStorage.getItem(key);
    let data:any = rehydration ? JSON.parse(rehydration) : {};
    if('object' == typeof value) {
      let keys = Object.keys(value);
      keys.forEach(_key => {
        if(-1 == this.localKeys.indexOf(_key as ApplicationStateKey)) {
          data[_key] = value[_key];
        }
      });
    }
    sessionStorage.setItem(key, JSON.stringify(data));
  }
}
