<div *ngIf="hasDiningOptions" class="dining-options place-widget place-options-widget">
    <div class="title">Dining Options</div>
    <ul class="options">
        <li *ngIf="curbsidePickup" class="option"><span class="material-symbols-outlined">no_crash</span> Curbside Pickup</li>
        <li *ngIf="delivery" class="option"><span class="material-symbols-outlined">local_pizza</span> Delivery</li>
        <li *ngIf="dineIn" class="option"><span class="material-symbols-outlined">dining</span> Dine In</li>
        <li *ngIf="menuForChildren" class="option"><span class="material-symbols-outlined">child_care</span> Children's Menu</li>
        <li *ngIf="servesBeer" class="option"><span class="material-symbols-outlined">sports_bar</span> Beer</li>
        <li *ngIf="servesBreakfast" class="option"><span class="material-symbols-outlined">breakfast_dining</span> Breakfast</li>
        <li *ngIf="servesBrunch" class="option"><span class="material-symbols-outlined">brunch_dining</span> Brunch</li>
        <li *ngIf="servesCocktails" class="option"><span class="material-symbols-outlined">local_bar</span> Cocktails</li>
        <li *ngIf="servesCoffee" class="option"><span class="material-symbols-outlined">coffee</span> Coffee</li>
        <li *ngIf="servesDessert" class="option"><span class="material-symbols-outlined">icecream</span> Dessert</li>
        <li *ngIf="servesDinner" class="option"><span class="material-symbols-outlined">dinner_dining</span> Dinner</li>
        <li *ngIf="servesLunch" class="option"><span class="material-symbols-outlined">lunch_dining</span> Lunch</li>
        <li *ngIf="servesVegetarianFood" class="option"><span class="material-symbols-outlined">rice_bowl</span> Vegetarian Food</li>
        <li *ngIf="servesWine" class="option"><span class="material-symbols-outlined">wine_bar</span> Wine</li>
        <li *ngIf="takeout" class="option"><span class="material-symbols-outlined">takeout_dining</span> Takeout</li>
        <li *ngIf="reservable" class="option"><span class="material-symbols-outlined">event_seat</span> Takes Reservations</li>
    </ul>
</div>