<button 
    aria-hidden="false"
    class="{{className}}"
    mat-button
    *ngIf="elementType == 1 && btnType.basic"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>
<button 
    aria-hidden="false"
    class="{{className}}"
    mat-fab
    *ngIf="elementType == 1 && btnType.fab"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>
<button 
    aria-hidden="false"
    class="{{className}}"
    mat-fab extended
    *ngIf="elementType == 1 && btnType.fab_extended"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>
<button 
    aria-hidden="false"
    class="{{className}}"
    mat-flat-button
    *ngIf="elementType == 1 && btnType.flat"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>
<button 
    aria-hidden="false"
    class="{{className}}"
    mat-icon-button
    *ngIf="elementType == 1 && btnType.icon"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>
<button 
    aria-hidden="false"
    class="{{className}}"
    mat-mini-fab
    *ngIf="elementType == 1 && btnType.mini_fab"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>
<button 
    aria-hidden="false"
    class="{{className}}"
    mat-raised-button
    *ngIf="elementType == 1 && btnType.raised"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>
<button 
    aria-hidden="false"
    class="{{className}}"
    mat-stroked-button
    *ngIf="elementType == 1 && btnType.stroked"
    [color]="color"
    [attr.aria-label]="ariaLabel"
    matTooltip="{{tooltip}}"
    [type]
    disabled="{{disabled}}"
    (click)="onClick($event)"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</button>

<a 
    mat-button
    aria-hidden="false"
    class="tooltip-button {{className}}"
    *ngIf="btnType.tooltip"
    [attr.disabled]="disabled"
    (click)="tooltipIcon.toggle()"
    matTooltip="{{tooltip}}"
>
    <mat-icon class="help" #tooltipIcon="matTooltip" matTooltip="{{tooltip}}" [ngClass]="iconClass">{{icon || 'info'}}</mat-icon>
</a>
<a 
    aria-hidden="false"
    class="{{className}}"
    mat-button
    *ngIf="elementType == 0 && btnType.basic"
    [attr.disabled]="disabled"
    [href]="anchorHref"
    [routerLink]="anchorHref"
    (click)="onClick($event)"
    matTooltip="{{tooltip}}"
    target="{{anchorTarget}}"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</a>
<a 
    aria-hidden="false"
    class="{{className}}"
    mat-flat-button
    *ngIf="elementType == 0 && btnType.flat"
    [attr.disabled]="disabled"
    [href]="anchorHref"
    [routerLink]="anchorHref"
    (click)="onClick($event)"
    matTooltip="{{tooltip}}"
    target="{{anchorTarget}}"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</a>
<a 
    aria-hidden="false"
    class="{{className}}"
    mat-raised-button
    *ngIf="elementType == 0 && btnType.raised"
    [attr.disabled]="disabled"
    [href]="anchorHref"
    [routerLink]="anchorHref"
    (click)="onClick($event)"
    target="{{anchorTarget}}"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</a>
<a 
    aria-hidden="false"
    class="{{className}}"
    mat-stroked-button
    *ngIf="elementType == 0 && btnType.stroked"
    [attr.disabled]="disabled"
    [href]="anchorHref"
    [routerLink]="anchorHref"
    (click)="onClick($event)"
    matTooltip="{{tooltip}}"
    target="{{anchorTarget}}"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</a>

@if (btnType.icon_link === true) {
<a 
    aria-hidden="false"
    class="icon-link {{className}}"
    *ngIf="btnType.icon_link"
    [attr.disabled]="disabled"
    [href]="anchorHref"
    [routerLink]="anchorHref"
    (click)="onClick($event)"
    matTooltip="{{tooltip}}"
    target="{{anchorTarget}}"
>
    <ng-container [ngTemplateOutlet]="iconTextTemplate" [ngTemplateOutletContext]="{icon, text}"></ng-container>
</a>
}

<ng-template #iconTextTemplate let-icon="icon" let-text="text">
    @if (icon?.position == 'append' && text) {{{ text }}@if (icon){&nbsp;}}
    @if (btnType.tooltip){
    <mat-icon
        aria-hidden="false"
        class="icon"
        #tooltipIcon="matTooltip"
        matTooltip="{{tooltip}}"
        [ngClass]="iconClass"
        [matBadge]="icon?.badge"
        [matBadgeHidden]="!hasIconBadge"
        [matBadgeOverlap]="true"
        matBadgeSize="large"
    >{{icon?.name || icon}}</mat-icon>}
    @else {
    <mat-icon 
        *ngIf="icon && !outlinedIcons"
        aria-hidden="false"
        [ngClass]="iconClass"
        [matBadge]="icon?.badge"
        [matBadgeHidden]="!hasIconBadge"
        [matBadgeOverlap]="true"
        matBadgeSize="large"
    >{{icon?.name || icon}}</mat-icon>
    <span
        *ngIf="icon && outlinedIcons"
        aria-hidden="false"
        class="material-symbols-outlined"
        matBadgeSize="large"
        [ngClass]="iconClass"
        [matBadge]="icon?.badge"
        [matBadgeHidden]="!hasIconBadge"
        [matBadgeOverlap]="true"
    >
        {{icon?.name || icon}}
    </span>
    }
    @if (text && (!icon?.position || icon.position == 'prepend' || icon.position != 'append')) {@if (icon){&nbsp;}{{text}}}
</ng-template>