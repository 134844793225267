import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ParkingOptions } from 'src/app/common/interfaces/place';

@Component({
  selector: 'app-place-parking',
  standalone: true,
  imports: [NgIf],
  templateUrl: './place-parking.component.html',
  styleUrl: './place-parking.component.scss'
})
export class PlaceParkingComponent implements OnInit {
  @Input() options: ParkingOptions | undefined;
  hasOptions = false;

  ngOnInit(): void {
    this.hasOptions = !!this.options && (
      this.options.freeGarageParking as boolean ||
      this.options.freeParkingLot as boolean ||
      this.options.freeStreetParking as boolean ||
      this.options.paidGarageParking as boolean ||
      this.options.paidParkingLot as boolean ||
      this.options.paidStreetParking as boolean ||
      this.options.valetParking as boolean
    );
  }
}
