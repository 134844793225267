import * as Sentry from "@sentry/angular-ivy";
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { SessionState } from '../interfaces/application-state';
import { ApiResponse } from '../interfaces/api-response';
import { LoginResult } from '../interfaces/auth';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  router = inject(Router);
  isLoggedIn$: Observable<boolean>;

  constructor(private stateService: StateService) {
    this.isLoggedIn$ = this.stateService.state$.pipe(map(state => state.session?.loggedIn || false));
  }

  getToken() {
    const state = this.stateService.getState('session');
    return state?.session?.jwt || "";
  }

  isActive(route: ActivatedRouteSnapshot) {
    // @todo: implement/expand session.isActive() logic.
    const state = this.stateService.getState('session');
    return !!state?.session?.loggedIn && route.url;
  }

  async onExpiredSession(_data?:any) {
    const loginUrl = '/users/login';
    const whitelist = [loginUrl, '/'];
    const blacklist = [/\/place\/[\w]+/gi,];
    const target = location && location?.pathname;
    const state:SessionState = {loggedIn: false};
    let redirectToLogin = false;
    let blacklisted = blacklist.find(v => target.match(v))
    if(blacklisted || target && -1 == whitelist.indexOf(target)) {
      state.redirectUrl = target;
      redirectToLogin = true;
    }
    this.setState(state);
    if(redirectToLogin) {
      await this.router.navigateByUrl(loginUrl);
    }
  }

  onLogin(response: ApiResponse<LoginResult>, credentials: any) {
    Sentry.setUser({ email: credentials?.email });
    if(response.data && response.data['token']) {
      this.setState({'loggedIn': true, jwt: response.data['token']});
    }

    const state = this.stateService.getState(`session`);
    var redirectUrl = `/dashboard`;
    if(state.session?.redirectUrl) {
      redirectUrl = state.session.redirectUrl;
      this.set(`redirectUrl`, null);
    }
    // Navigate to the target (if intercepted routing) or dashboard page
    this.router.navigate([redirectUrl]);
  }

  set(key: string, value: any) {
    const state = {[key]: value} as SessionState;
    return this.setState(state);
  }

  setState(session: SessionState) {
    this.stateService.setState({session});
  }
}
