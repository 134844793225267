<mat-toolbar class="toolbar">
  @if ('footer' != mode) {
  <app-button
    *ngIf="isHandset" 
    aria-label="Toggle sidenav"
    [buttonType]="'mat-icon-button'"
    [icon]="'menu'"
    class="toolbar--button"
    (click)="onToggleMenu()"
  >
  </app-button>
  <a class="toolbar--title" routerLink="/">
    <mat-icon aria-label="toolbar--icon" class="logo toolbar--icon">map</mat-icon>
    <span class="consonant">R</span>
    <span class="vowel">E</span>
    <span class="consonant">N</span>
    <span class="consonant">D</span>
    <span class="vowel">E</span>
    <span class="consonant">Z</span>
    <span class="consonant">V</span>
    <span class="vowel">O</span>
    <span class="vowel">U</span>
    <span class="consonant">S</span>
    <span class="separator">|</span>
    <span class="suffix">AI</span>
    <span class="beta">BETA</span>
  </a>
  <span class="toolbar--spacer"></span>
  <ng-container *ngIf="!isHandset">
    <app-navigation [viewportClass]="viewportClass" class="toolbar--nav"></app-navigation>
  </ng-container>
} @else {
  @if (isLoggedIn) {
  <div class="toolbar--controls">
    <div class="toolbar--control">
      <app-button
        [anchorHref]="'/dashboard/'"
        [anchorTarget]="'_self'"
        [buttonType]="'mat-mini-fab'"
        [color]="'basic'"
        [icon]="'dashboard'"
        [elementType]="1"
        [outlinedIcons]="true"
        [routerLink]="'/dashboard/'"
        [routerLinkActive]="'active'"
        [type]="'button'"
        aria-label="Go to my Dashboard"
        class="toolbar--button"
      >
      </app-button>
      <span class="toolbar--control-name">My Dashboard</span>
    </div>
    <div class="toolbar--control">
      <app-button
        [buttonType]="'mat-mini-fab'"
        [color]="'primary'"
        [icon]="'support_agent'"
        [elementType]="1"
        aria-label="Show Copilot"
        class="toolbar--button"
        (click)="showCopilot()"
      >
      </app-button>
      <span class="toolbar--control-name">My Copilot</span>
    </div>
    <div class="toolbar--control">
      <app-button
        [anchorHref]="'/explore/'"
        [anchorTarget]="'_self'"
        [buttonType]="'mat-mini-fab'"
        [color]="'basic'"
        [icon]="'search'"
        [elementType]="1"
        [outlinedIcons]="true"
        [routerLink]="'/explore/'"
        [routerLinkActive]="'active'"
        [type]="'button'"
        aria-label="Explore and Find Places"
        class="toolbar--button"
      >
      </app-button>
      <span class="toolbar--control-name">Explore</span>
    </div>
  </div>
  }
}
</mat-toolbar>