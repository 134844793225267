import * as Sentry from '@sentry/angular-ivy';
import {
  APP_INITIALIZER,
  ErrorHandler,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { environment } from './environments/environment';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { HttpInterceptorFns } from './app/common/interceptors';

// @todo: Make Sentry environment specific.
Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: environment.sentry.tracesSampleRate,
  tracePropagationTargets: environment.sentry.tracePropagationTargets,
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
  replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      MatSidenavModule,
      RecaptchaV3Module,
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
    ),
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withInterceptors(HttpInterceptorFns),
      withInterceptorsFromDi()
    ),
    provideMomentDateAdapter(undefined, {}),
  ],
}).catch((err) => console.error(err));
