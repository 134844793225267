import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NewTripRequest, Trip } from '../interfaces/trips';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../interfaces/api-response';
import { NewMeetingResponse } from '../interfaces/meeting';
import { ApplicationState } from '../interfaces/application-state';
import { StateService } from './state.service';
import { BehaviorSubject, Observable, of } from 'rxjs';

const defaultState: ApplicationState = {
  trips: []
};

@Injectable({
  providedIn: 'root'
})
export class TripService {

  private stateService = inject(StateService);
  MAX_TRIP_COUNT: number = 100; // @todo: Implement permanent logic to prevent overcaching.

  constructor(private http: HttpClient) {}

  async create(request: NewTripRequest) {
    return this.http.post<ApiResponse<NewMeetingResponse>>(environment.endpoints.trips.uri+'/', request);
  }

  fetch(tripId:string) {
    const searchResult$ = new BehaviorSubject<Trip>({} as Trip);
    const state = this.stateService.getState() || defaultState;
    if(state?.trips && state.trips?.length) {
      const trip = state.trips.find(trip => {
        return trip.id == tripId || trip._id == tripId;
      });
      if(trip) {
        return of(trip);
      }
    }

    this.http.get<ApiResponse<Trip[]>>(environment.endpoints.trips.uri+'/'+tripId)
      .subscribe({
        error: error => searchResult$.error(error),
        next: trip => {
          if(trip?.data && trip.data.length) {
            state.trips?.push(trip.data[0]);
            this.stateService.setState({ trips: state.trips || [] });
            searchResult$.next(trip?.data[0]);
          } else {
            searchResult$.error('TRIP NOT FOUND');
          }
        }
      });
    return searchResult$.asObservable()
  }

  find(ids: string[]) {
    let trips:Trip[] = [];
    let idsToFetch: string[] = [];
    const results$ = new BehaviorSubject<Trip[]|null>(null);
    const localTrips = this.stateService.getState()?.trips;
    
    if(localTrips && localTrips?.length) {
      ids.forEach(id => {
        let trip = localTrips.find(trip => trip.id == id || trip._id == id);
        if(trip) {
          trips.push(trip);
        } else {
          idsToFetch.push(id);
        }
      });
    }

    if(!idsToFetch.length) {
      results$.next(trips);
      return results$.asObservable();
    }

    this.http.post<ApiResponse<Trip[]>>(environment.endpoints.trips.uri+'/find', { ids: idsToFetch })
    .subscribe({
      error: error => results$.error(error),
      next: _trips => {
        if(_trips && _trips?.data?.length) {
          this._updateTripsFromServer(_trips?.data);
        }
        results$.next(trips.concat(_trips?.data as Trip[]));
      }
    })
    return results$.asObservable();
  }

  list() {
    return new Observable<Trip[]>(subscriber => {
      const state = this.stateService.getState() || defaultState;
      if(state?.trips && state?.trips?.length) { return subscriber.next(state?.trips); }
      this.http.get<ApiResponse<Trip[]>>(environment.endpoints.trips.uri+'/')
        .subscribe({
          error: error => subscriber.error(error),
          next: trips => {
            if(trips?.data && trips.data?.length) {
              if(trips.data.length > this.MAX_TRIP_COUNT) {
                trips.data = trips.data.slice(0, this.MAX_TRIP_COUNT-1);
              }
              this.stateService.setState({ trips: trips.data });
            }
            subscriber.next(trips?.data);
          }
        })
    });
  }

  refresh() {
    const refreshResult$ = new BehaviorSubject<Trip[]|null>(null);
    this.http.get<ApiResponse<Trip[]>>(environment.endpoints.trips.uri+'/')
    .subscribe({
      error: error => refreshResult$.error(error),
      next: trips => {
        this._updateTripsFromServer(trips?.data);
        refreshResult$.next(trips?.data as Trip[]);
      }
    })
    return refreshResult$.asObservable();
  }

  private _updateTripsFromServer(trips?: Trip[]) {
    if(trips && trips?.length) {
      if(trips.length > this.MAX_TRIP_COUNT) {
        trips = trips.slice(0, this.MAX_TRIP_COUNT-1);
      }
      this.stateService.setState({ trips });
    }
  }
}
