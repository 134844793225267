import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-place-vibe',
  standalone: true,
  imports: [NgIf],
  templateUrl: './place-vibe.component.html',
  styleUrl: './place-vibe.component.scss'
})
export class PlaceVibeComponent implements OnInit {
  hasOptions = false;
  @Input() allowsDogs: boolean | undefined = false;
  @Input() goodForChildren: boolean | undefined = false;
  @Input() goodForGroups: boolean | undefined = false;
  @Input() goodForWatchingSports: boolean | undefined = false;
  @Input() liveMusic: boolean | undefined = false;
  @Input() outdoorSeating: boolean | undefined = false;

  ngOnInit(): void {
    this.hasOptions =
    this.allowsDogs as boolean ||
    this.goodForChildren as boolean ||
    this.goodForGroups as boolean ||
    this.goodForWatchingSports as boolean ||
    this.liveMusic as boolean ||
    this.outdoorSeating as boolean;
  }
}