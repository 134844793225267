import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NavigationComponent } from '../navigation/navigation.component';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/common/services/session.service';
import { CopilotComponent } from '../copilot/copilot.component';
import { ButtonComponent } from "../button/button.component";
import { ViewportSizeService } from 'src/app/common/services/viewport-size.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: true,
    imports: [
      ButtonComponent,
      MatToolbar,
      MatIconModule,
      NavigationComponent,
      NgIf,
      RouterLinkActive,
      RouterLinkWithHref,
    ]
})
export class ToolbarComponent {

  /**
   * Callback function for toggling menus
   */
  @Output() menuToggle = new EventEmitter<object|string>();

  /**
   * Whether or not current viewport sizing is handset-sized
   */
  @Input()
  isHandset?: boolean | null;

  /** The type of toolbar to render. Options are `header` or `footer`. */
  @Input() mode: 'footer' | 'header' = 'header';

  /**
   * The title displayed in the toolbar
   */
  @Input()
  title?: string | null;

  /** The viewport class 'handheld', 'tablet', or 'desktop' */
  @Input()
  viewportClass!: string|string[]|Set<string>|{ [klass: string]: any; };

  panelClass!: string|string[];
  isLoggedIn = false;

  readonly dialog = inject(MatDialog);
  readonly session = inject(SessionService);
  readonly viewport = inject(ViewportSizeService);

  constructor() {
    this.session.isLoggedIn$.subscribe(loggedIn => {
      this.isLoggedIn = !!loggedIn;
    });

    this.viewport.viewportClasses$.subscribe((classes) => {
      this.panelClass = classes;
      this.updateOpenDialogs();
    });
  }

  private updateOpenDialogs(): void {
    let dialog = this.dialog.getDialogById('copilot');
    if (dialog) {
      dialog.removePanelClass(['desktop', 'tablet', 'handheld', 'xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge']);
      dialog.addPanelClass(this.panelClass);
    }
  }

  onToggleMenu(): void {
    this.menuToggle.emit(this);
  }

  showCopilot() {
    this.dialog.open(CopilotComponent, {
      id: 'copilot',
      closeOnNavigation: true,
      height: '100%',
      width: '100%',
      panelClass: this.panelClass,
    });
  }
}
