export enum AppMode {
    /** Searches are interactions are optimized for group event plannings. */
    groups = 'groups',
    /** Searches are interactions are optimized for individual event planning. */
    solo = 'solo',
};

export enum AppTheme {
    /** The default light application theme. */
    default = '',
    /** A monsoon-ui experience theme. */
    monsoon = 'themed theme-monsoon',
    /** A tornado-ui experience theme. */
    tornado = 'themed theme-tornado',
}