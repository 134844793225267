import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { SessionService } from '../services/session.service';

export const AuthHeaderInterceptorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const session = inject(SessionService);
  const jwt = session.getToken();
  if(jwt) {
    req = req.clone({
      headers: req.headers.set('Authorization', jwt),
    });
  }
  return next(req);
};
