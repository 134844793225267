import { Component, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { CdkScrollable, ScrollingModule } from '@angular/cdk/scrolling';
import { NavigationSkipped, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';
import { distinctUntilChanged, Observable } from 'rxjs';
import { Viewport } from './common/enums/viewport';
import { AppMode } from './common/enums/app';
import { ViewportSizeService } from './common/services/viewport-size.service';
import { AppModeService } from './common/services/app-mode.service';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatIconRegistry } from '@angular/material/icon';
import { SessionService } from './common/services/session.service';
import { SocketService } from './common/services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MatSidenavModule, NgIf, NavigationComponent, ToolbarComponent, RouterOutlet, AsyncPipe, ScrollingModule]
})
export class AppComponent implements OnInit {

  bodyClass = AppMode.solo as string;
  desktopViewportClass = Viewport.desktop;
  handsetViewportClass = Viewport.handset;

  @ViewChild('drawer') drawer: MatDrawer | undefined;
  @ViewChild('content') contentContainer: ElementRef<HTMLElement> | undefined;
  @ViewChild(CdkScrollable, {static: false}) scrollContainer!: CdkScrollable;

  private modeService = inject(AppModeService);
  private viewport = inject(ViewportSizeService);
  private router = inject(Router);
  private session = inject(SessionService);
  private socket = inject(SocketService);

  isHandset$: Observable<boolean> = this.viewport.isHandset$;
  isScrolled: boolean = false;
  headerThreshold: number = 32;
  mode: AppMode | undefined = AppMode.solo;
  scrollTop: number = 0;
  title = 'RENDEZVOUS|AI'; // @todo: Remove. Not used.
  updateCss = true;
  updatedCss = false;
  viewportClass = '';
  isLoggedIn = false;

  constructor(private iconRegistry: MatIconRegistry) {
    this.session.isLoggedIn$.pipe(distinctUntilChanged()).subscribe(loggedIn => {
      this.isLoggedIn = !!loggedIn;
      this.toggleSocketConnection(!!loggedIn);
    });
  }

  private initScrolling() {
    this.scrollTop = this.scrollContainer?.measureScrollOffset('top');
    this.isScrolled = this.scrollTop >= this.headerThreshold;
    this.updateCss = this.isScrolled;
    this.updatedCss = false;
  }

  ngOnInit() {
    // this.initSocket();
    this.initScrolling();
    this.modeService.appMode$.subscribe(mode => {
      this.mode = mode;
      this.setBodyClass();
    });
    this.initRoutingDetection();

    this.viewport.viewportClasses$.subscribe((classes) => {
      this.viewportClass = classes.join(' ');
    });

    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }

  initRoutingDetection() {
    this.router.events.subscribe((event) => {
      if (this.drawer?.opened && event instanceof NavigationStart || event instanceof NavigationSkipped) {
        this?.drawer?.close();
      }
    });
  }

  @HostListener('body:scroll', ['$event'])
  onContainerScroll() {
    this.scrollTop = this.scrollContainer?.measureScrollOffset('top');
    this.isScrolled = this.scrollTop >= this.headerThreshold;

    if(!this.isScrolled || this.isScrolled && this.updateCss) {
      this.setBodyClass();
      this.updateCss = true;
    }

    if(this.isScrolled) {
      this.updateCss = false;
    }
  }

  setBodyClass() {
    this.bodyClass = this.mode as string;
    this.bodyClass+= this.isScrolled ? ' scrolled' : '';
  }

  toggleMenu() {
    this.drawer?.toggle()
  }

  toggleSocketConnection(loggedIn: boolean) {
    if(loggedIn) {
      this.socket.connect({
        token: this.session.getToken(),
      });
    } else {
      this.socket.disconnect();
    }
  }
}
