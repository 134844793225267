import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-place-dining',
  standalone: true,
  imports: [NgIf],
  templateUrl: './place-dining.component.html',
  styleUrl: './place-dining.component.scss'
})
export class PlaceDiningComponent implements OnInit {
  @Input() curbsidePickup: boolean | undefined = false;
  @Input() delivery: boolean | undefined = false;
  @Input() dineIn: boolean | undefined = false;
  @Input() menuForChildren: boolean | undefined = false;
  @Input() reservable: boolean | undefined = false;
  @Input() servesBeer: boolean | undefined = false;
  @Input() servesBreakfast: boolean | undefined = false;
  @Input() servesBrunch: boolean | undefined = false;
  @Input() servesCocktails: boolean | undefined = false;
  @Input() servesCoffee: boolean | undefined = false;
  @Input() servesDessert: boolean | undefined = false;
  @Input() servesDinner: boolean | undefined = false;
  @Input() servesLunch: boolean | undefined = false;
  @Input() servesVegetarianFood: boolean | undefined = false;
  @Input() servesWine: boolean | undefined = false;
  @Input() takeout: boolean | undefined = false;

  hasDiningOptions = false;

  ngOnInit(): void {
    this.hasDiningOptions =
      this.curbsidePickup as boolean ||
      this.delivery as boolean ||
      this.dineIn as boolean ||
      this.menuForChildren as boolean ||
      this.reservable as boolean ||
      this.servesBeer as boolean ||
      this.servesBreakfast as boolean ||
      this.servesBrunch as boolean ||
      this.servesCocktails as boolean ||
      this.servesCoffee as boolean ||
      this.servesDessert as boolean ||
      this.servesDinner as boolean ||
      this.servesLunch as boolean ||
      this.servesVegetarianFood as boolean ||
      this.servesWine as boolean ||
      this.takeout as boolean;
  }
}
