<form class="full-width" [formGroup]="formGroup">
    <mat-form-field [class]="class">
        <mat-label>{{ label || placeholder || name }}</mat-label>
        @if (prefixIcon) {
        <mat-icon matPrefix>{{prefixIcon}}</mat-icon>
        }
        <input 
            matInput
            [autocomplete]="autocomplete"
            [matAutocompleteDisabled]="autocompleteDisabled"
            [id]="id"
            [maxlength]="maxlen"
            [name]="name || formControlName"
            [required]="required"
            [type]="type || 'text'"
            [placeholder]="placeholder"
            [formControl]="inputControl"
            [placeholder]="placeholder" 
            [formControlName]="formControlName"
            (cdkAutofill)="onAutoFill($event)"
            (keypress)="onKeyPress($event)"
            (keyup)="onKeyUp($event)"
            (ngModelChange)="onNgModelChange($event)"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"
            (change)="onChanged($event)"
            [matAutocomplete]="auto"
        >

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAutoCompletion($event)">
        @for (option of autoCompleteOptions; track option) {
            <mat-option [value]="option.value">{{option.viewValue}}</mat-option>
        }
        </mat-autocomplete>

        @if (helpTooltip) {
        <mat-icon matSuffix class="help outlined" #help="matTooltip" matTooltip="{{helpTooltip}}">{{helpTooltipIcon || 'help'}}</mat-icon>
        }
        <mat-hint align="end" *ngIf="hint">{{ hint }}</mat-hint>
        @for (error of inputControl.errors | keyvalue; track $index) {
        @if(inputControl.touched && errors[error.key]){
        <mat-error>
            {{ errors[error.key] }}
        </mat-error>
        }}
        <ng-content></ng-content>
    </mat-form-field>
</form>