import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PlaceTypeIcon, PlaceTypeIconKeyType, SecondaryPlaceTypeIcon } from 'src/app/common/enums/place';

@Component({
  selector: 'app-place-icon',
  standalone: true,
  imports: [NgIf],
  templateUrl: './place-icon.component.html',
  styleUrl: './place-icon.component.scss'
})
export class PlaceIconComponent implements OnInit {

  showIcon = false;
  typeIcon = "";

  @Input() type!: string;

  ngOnInit(): void {
    this.showIcon = true;
    this.setTypeIcon(this.type as PlaceTypeIconKeyType);
    if(!this.typeIcon) {
      if(this.type.endsWith('_restaurant')) {
        this.setTypeIcon('restaurant');
      } else if(this.type.endsWith('_store')) {
        this.setTypeIcon('store');
      } else {
        this.setTypeIcon('storefront');
      }
    }
  }

  setTypeIcon(type: PlaceTypeIconKeyType|keyof typeof SecondaryPlaceTypeIcon) {
    this.type = type;
    this.typeIcon = PlaceTypeIcon[this.type as PlaceTypeIconKeyType] ?? SecondaryPlaceTypeIcon[this.type as keyof typeof SecondaryPlaceTypeIcon];
  }
}
