import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHeaderInterceptorInterceptor } from './auth-header-interceptor.interceptor';
import { UnauthorizedErrorInterceptorInterceptor } from './unauthorized-error-interceptor.interceptor';

/** Array of Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedErrorInterceptorInterceptor, multi: true },
];

export const HttpInterceptorFns = [ AuthHeaderInterceptorInterceptor,  UnauthorizedErrorInterceptorInterceptor,];