import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../interfaces/api-response';
import { environment } from 'src/environments/environment';
import { Bookmark, BookmarkDeleteRequest } from '../interfaces/bookmark';
import { StateService } from './state.service';
import { Observable } from 'rxjs';
import { SocketService } from './socket.service';
import { SocketEvents } from '../enums/events';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {

  constructor(
    private http: HttpClient,
    private state: StateService,
    private socket: SocketService,
  ) {}

  check(placeId: string | undefined) {
    return new Observable<boolean>(observer => {
      if(!placeId) {
        observer.next(false);
        return;
      }
      let bookmarks = this.state.getState().bookmarks;
      if(!bookmarks) {
        this.http.get<ApiResponse<boolean>>(environment.endpoints.bookmarks.uri+'/'+placeId)
        .subscribe((response: ApiResponse<boolean>) => {
          if(response.success) {
            bookmarks = this.state.getState().bookmarks || {};
            bookmarks[placeId] = !!response.data;
            observer.next(bookmarks[placeId] === true)
            this.state.setState({bookmarks});
          }
        }).add(() => observer.complete());
      }
      else observer.next(!!bookmarks[placeId]);
    });
  }

  async create(data: any) {
    return await this.http.post<ApiResponse<any>>(environment.endpoints.bookmarks.uri+'/', data);
  }

  async delete(body: BookmarkDeleteRequest) {
    return await this.http.delete<ApiResponse<any>>(environment.endpoints.bookmarks.uri+'/'+body.place, {body});
  }

  list() {
    return this.http.get<ApiResponse<Bookmark[]>>(environment.endpoints.bookmarks.uri+'/');
  }

  onLogin() {
    this.http.get<ApiResponse<Bookmark[]>>(environment.endpoints.bookmarks.uri+'/ids')
      .subscribe((response: ApiResponse<Bookmark[]>) => {
        if(response.success) {
          let bookmarks: {[key:string]: boolean} = {};
          response?.data?.forEach((bookmark: Bookmark) => {
            bookmarks[bookmark.place._id || bookmark.place.id!] = true;
          });
          this.state.setState({bookmarks});
        }
      });

    this.socket.registerListener(SocketEvents.bookmarkCreated, (data: any) => {
      let bookmarks = this.state.getState().bookmarks || {};
      if(!bookmarks[data.place]) {
        bookmarks[data.place] = true;
        this.state.setState({bookmarks});
      }
    });

    this.socket.registerListener(SocketEvents.bookmarkDeleted, (data: any) => {
      let bookmarks = this.state.getState().bookmarks || {};
      if(false !== bookmarks[data.place]) {
        bookmarks[data.place] = false;
        this.state.setState({bookmarks});
      }
    });
  }

  setState(id: string, value: boolean) {
    let bookmarks = this.state.getState().bookmarks || {};
    bookmarks[id] = value;
    this.state.setState({bookmarks});
  }
}
