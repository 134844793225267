import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { Place } from 'src/app/common/interfaces/place';
import { PlaceComponent } from "../place/place.component";
import { ViewportSizeService } from 'src/app/common/services/viewport-size.service';
import { StateService } from 'src/app/common/services/state.service';
import { LookupResult } from 'src/app/common/interfaces/search';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-place-list',
    standalone: true,
    templateUrl: './place-list.component.html',
    styleUrl: './place-list.component.scss',
    imports: [NgClass, NgForOf, PlaceComponent, MatIcon,]
})
export class PlaceListComponent implements OnInit {

  private stateService = inject(StateService);
  private viewport = inject(ViewportSizeService);

  viewportClasses: string[] = [];

  @Input() places!: Place[] | null;
  @Output() placeBookmarked = new EventEmitter<Place>();
  @Output() placeScheduled = new EventEmitter<Place>();

  constructor() {}

  ngOnInit(): void {

    if (!this.places || !this.places.length) {
      let previousResults = {} as LookupResult;
      const searchState = this.stateService.getState()?.lookup;
      const lastSearchKey = searchState?.lastSearchKey;
      if(lastSearchKey && !!searchState?.results) {
        const previousSearches = searchState.results as {[key:string]: LookupResult};
        previousResults = previousSearches[lastSearchKey];
      }

      this.places = !!previousResults && previousResults?.places?.length ? previousResults?.places : [];
    }

    this.viewport.viewportClasses$.subscribe((view) => {
      this.viewportClasses = view;
    })
  }

  onBookmarkPlace(_place: Place) {
    this.placeBookmarked.emit(_place);
  }

  onSchedulePlace(_place: Place) {
    this.placeScheduled.emit(_place);
  }
}
