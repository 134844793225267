import * as Sentry from '@sentry/angular-ivy';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthorAttribution, Place } from 'src/app/common/interfaces/place';
import { BusinessStatusName, BusinessStatusNameKey } from 'src/app/common/enums/place';
import { ButtonColor, ButtonType } from '../../common/enums/button';
import { CardComponent } from "../card/card.component";
import { PlaceIconComponent } from "../place-icon/place-icon.component";
import { RatingComponent } from '../rating/rating.component';
import { BookmarkService } from 'src/app/common/services/bookmark.service';
import { ApiResponse } from 'src/app/common/interfaces/api-response';
import { SnackBarService } from 'src/app/common/services/snack-bar.service';
import { PlaceScheduleComponent } from "../place-schedule/place-schedule.component";
import { PlaceDiningComponent } from "../place-dining/place-dining.component";
import { PlaceAccessibilityComponent } from "../place-accessibility/place-accessibility.component";
import { PlaceParkingComponent } from "../place-parking/place-parking.component";
import { PlacePaymentComponent } from "../place-payment/place-payment.component";
import { PlaceVibeComponent } from "../place-vibe/place-vibe.component";
import { PlaceContactComponent } from "../place-contact/place-contact.component";
import { PlaceStatusComponent } from "../place-status/place-status.component";
import { PlacePricingComponent } from "../place-pricing/place-pricing.component";
import { PlaceEvChargingComponent } from "../place-ev-charging/place-ev-charging.component";
import { ButtonComponent } from '../button/button.component';
import { SocketService } from 'src/app/common/services/socket.service';
import { SocketEvents } from 'src/app/common/enums/events';

interface Render {
  showAccessibilityWidget?: boolean;
  showBookmarkBtn?:boolean;
  showContactWidget?: boolean;
  showDiningWidget?: boolean;
  showEditorialSummary?: boolean;
  showEvChargeOptionsWidget?: boolean;
  showImageAttribution?:boolean;
  showParkingWidget?: boolean;
  showPaymentWidget?: boolean;
  showPriceLevelsWidget?:boolean;
  showRatingWidget?:boolean;
  showScheduleWidget?: boolean;
  showSchedulerBtn?:boolean;
  showTypeWidget?:boolean;
  showTypeWidgetAboveTitle?:boolean;
  showVibeWidget?: boolean;
}

@Component({
    selector: 'app-place',
    standalone: true,
    templateUrl: './place.component.html',
    styleUrl: './place.component.scss',
    imports: [
      ButtonComponent,
      NgIf,
      PlaceIconComponent,
      RatingComponent,
      RouterModule,
      PlaceScheduleComponent,
      PlaceDiningComponent,
      PlaceAccessibilityComponent,
      PlaceParkingComponent,
      PlacePaymentComponent,
      PlaceVibeComponent,
      PlaceContactComponent,
      PlaceStatusComponent,
      PlacePricingComponent,
      MatTooltip,
      PlaceEvChargingComponent
  ]
})
export class PlaceComponent implements OnInit {

  @Input() place!: Place;
  @Input() bookmarked:boolean|null = null;
  /** Whether component is being rendered as a detailed place page (as opposed to being rendered in a place result list). */
  @Input() profile: 'full' | 'compact' | 'mini' = 'full';

  @Input()
  //** Whether component is being rendered as a detailed place page (as opposed to being rendered in a place result list). */
  isDetailedView = false;

  @Input()
  //** Whether component is being rendered by a premium user. */
  isPremiumView = false;

  @Output() onBookmarkPlace = new EventEmitter<Place>();
  @Output() onSchedulePlace = new EventEmitter<Place>();

  router = inject(Router);
  bookmarker = inject(BookmarkService);
  snackbar = inject(SnackBarService);
  socket = inject(SocketService);

  placeImage: AuthorAttribution = {
    displayName: '',
    photoUri: '',
    uri: '',
  };
  isOpenNow: boolean | undefined;
  hasRating: number | undefined;
  props!: { address: { icon: string; value: string | undefined; }; openStatus: BusinessStatusName; phone: { icon: string; value: string | undefined; }; };
  card = {
    bookmarkLinkColor: ButtonColor.primary,
    scheduleLinkType: ButtonType.icon,
    bookmarkLinkType: ButtonType.icon,
  };

  profiles: {[key:string]:Render} = {
    compact: {
      showBookmarkBtn: true,
      showContactWidget: true,
      showPriceLevelsWidget: true,
      showRatingWidget: true,
      showTypeWidget: true,
      showTypeWidgetAboveTitle: true,
    },
    full: {
      showAccessibilityWidget: true,
      showBookmarkBtn: true,
      showContactWidget: true,
      showDiningWidget: true,
      showEditorialSummary: true,
      showEvChargeOptionsWidget: true,
      showImageAttribution: true,
      showParkingWidget: true,
      showPaymentWidget: true,
      showPriceLevelsWidget: true,
      showRatingWidget: true,
      showScheduleWidget: true,
      showSchedulerBtn: true,
      showTypeWidget: true,
      showTypeWidgetAboveTitle: false,
      showVibeWidget: true,
    },
    mini: {
      showContactWidget: true,
      showPriceLevelsWidget: true,
      showRatingWidget: true,
    },
  }

  render = this.profiles[this.profile];

  ngOnInit(): void {
    this.checkPlaceBookmarkStatus();
    this.setPlaceValues();
    this.setPlaceImage();
    this.setupSocketEventListeners();
  }

  setupSocketEventListeners() {

    this.socket.registerListener(SocketEvents.bookmarkCreated, (data: any) => {
      if(data.place === this.place._id || data.place === this.place.id) {
        this.bookmarked = true;
      }
    });

    this.socket.registerListener(SocketEvents.bookmarkDeleted, (data: any) => {
      if(data.place === this.place._id || data.place === this.place.id) {
        this.bookmarked = false;
      }
    });
  }

  bookmarkPlace() {
    this.bookmarker.create({place: this.place._id || this.place.id})
    .then((_value) => _value.subscribe({
      next: o => {
        this.onBookmarkPlace.emit(this.place);
        this.bookmarked = true;
        this.bookmarker.setState(this.place._id || this.place.id!, true);
        this.snackbar.show(this.place?.displayName?.text+' has been added to your bookmarks.', 'OK', {}, null);
      },
      error: (e) => {
        console.log(e);
        Sentry.captureException(e);
      }}));
  }

  checkPlaceBookmarkStatus() {
    if(this.bookmarked === null) {
      this.bookmarker.check(this.place._id || this.place.id)
      .subscribe({
        next: (value) => {
          this.bookmarked = value;
        },
        error: (err:any) => {
          this.bookmarked = false;
          Sentry.captureException(err);
        },
      });
    }
  }

  unbookmarkPlace() {
    const _this = this;
    this.bookmarker.delete({place: this.place._id || this.place.id!})
    .then(_value => _value.subscribe({
      next: (_value: ApiResponse<any>) => {
        this.bookmarked = false;
        this.bookmarker.setState(this.place._id || this.place.id!, false);
        this.snackbar.show(this.place?.displayName?.text+' has been removed from your bookmarks.', 'UNDO', {
          next: () => { _this.bookmarkPlace();}
        }, null);
      },
      error: (err: any) => {console.log(err)}
    }));
  }

  setPlaceImage() {
    if(this.place?.image) {
      const { authorAttributions, photo } = this.place.image;
      this.placeImage = {
        displayName: authorAttributions && authorAttributions?.length ? authorAttributions[0]?.displayName : '',
        photoUri: photo?.photoUri
      };
    }
  }

  schedulePlace(event: Event) {
    event.stopPropagation();
    this.onSchedulePlace.emit(this.place);
    this.router.navigate(['/users', 'planner', 'add'], {state: {venue: this.place}});
  }

  setPlaceValues() {
    this.isOpenNow = this.place?.currentOpeningHours?.openNow;
    this.hasRating = this.place?.rating && this.place?.userRatingCount;
    this.props = {
      address: {
        icon: 'home_pin',
        value: this.place.formattedAddress,
      },
      openStatus: BusinessStatusName[this.place.businessStatus as BusinessStatusNameKey],
      phone: {
        icon: 'call',
        value: this.place.internationalPhoneNumber,
      },
    }
  }
}
