import { Component, Input, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PromptInterfaceComponent } from 'src/app/modules/users/assistant/components/prompt-interface/prompt-interface.component';
import { ButtonComponent } from "../button/button.component";
import { Router } from '@angular/router';
import { SessionService } from 'src/app/common/services/session.service';

@Component({
    selector: 'app-copilot',
    standalone: true,
    templateUrl: './copilot.component.html',
    styleUrl: './copilot.component.scss',
    imports: [PromptInterfaceComponent, ButtonComponent]
})
export class CopilotComponent {

  /** Boolean indication of whether the user is logged in. */
  @Input() isLoggedIn = false;

  readonly dialogRef = inject(MatDialogRef<PromptInterfaceComponent>);
  readonly router = inject(Router);

  constructor(private session: SessionService) {
    this.session.isLoggedIn$.subscribe(loggedIn => {
      this.isLoggedIn = !!loggedIn;
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  onLoginRequest() {
    this.closeModal();
    this.router.navigateByUrl('/users/login');
  }
}
