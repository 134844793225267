import * as Sentry from '@sentry/angular-ivy';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  /** Snackbar duration (milliseconds). Defaults to `6000`. */
  duration: number | undefined = 6000;

  /** Snackbar reference. */
  private ref!: MatSnackBarRef<any> | null;

  constructor(private _service: MatSnackBar) {}

  dismiss(withAction = false) {
    if(this.ref) {
      !withAction ? this.ref.dismiss() : this.ref.dismissWithAction();
    }
  }

  show(message: string, cta = 'CLOSE', actionCallback?: Partial<Observer<void>>, dismissCallback?: any, duration: number = -1) {
    this.ref = this._service.open(message, cta, {
      duration: -1 != duration ? duration : this.duration,
    });

    if(dismissCallback) {
      this.ref?.afterDismissed().subscribe(dismissCallback);
    }

    if(actionCallback) {
      this.ref?.onAction().subscribe(actionCallback);
    }
  }
}
