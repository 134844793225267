import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PriceLevel } from 'src/app/common/enums/place';

@Component({
  selector: 'app-place-pricing',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './place-pricing.component.html',
  styleUrl: './place-pricing.component.scss'
})
export class PlacePricingComponent implements OnInit {

  @Input() priceLevel: PriceLevel | undefined;
  @Input() showText = false;
  @Input() showTitle = false;

  hasPriceLevel = false;

  ngOnInit(): void {
    this.hasPriceLevel = !!this.priceLevel && this.priceLevel != PriceLevel.PRICE_LEVEL_UNSPECIFIED;
  }

  getPriceClass() {
    switch(this.priceLevel) {
      case PriceLevel.PRICE_LEVEL_UNSPECIFIED:
        return 'unspecified';
      case PriceLevel.PRICE_LEVEL_FREE:
      case PriceLevel.PRICE_LEVEL_INEXPENSIVE:
        return 'inexpensive';
      case PriceLevel.PRICE_LEVEL_EXPENSIVE:
        return 'expensive';
      case PriceLevel.PRICE_LEVEL_MODERATE:
        return 'moderate';
      case PriceLevel.PRICE_LEVEL_VERY_EXPENSIVE:
        return 'very_expensive';
      default: return '';
    }
  }
}
