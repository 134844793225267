<div class="contacts-list-container">
@if(contacts) {
    <label *ngIf="showLabel" class="contacts-list--label" id="contacts-list-label">
        Contacts
    </label>

    @if(contacts.length && virtualScrollThreshold < contacts.length) {
    <cdk-virtual-scroll-viewport
        cdkListbox
        [attr.ariaLabelledby]="labelId"
        class="contacts virtual-list"
        itemSize="72"
    >
        <div
            *cdkVirtualFor="let contact of contacts"
            cdkOption="contact"
            class="contact row"
        >
        <ng-container [ngTemplateOutlet]="contactTemplate" [ngTemplateOutletContext]="{contact}"></ng-container>
        </div>
    </cdk-virtual-scroll-viewport>
    } @else {
    <div class="contacts list">
        @for (contact of contacts; track $index) {
        <div class="contact row">
            <ng-container [ngTemplateOutlet]="contactTemplate" [ngTemplateOutletContext]="{contact}"></ng-container>
        </div>
        }
    </div>
    }
}
</div>

<ng-template #contactTemplate let-contact="contact">

    <div class="info avatar-container">
        <div class="avatar" [dynamicBgColor]="''">
            {{ getLetter(contact) }}
        </div>
    </div>

    <div class="info pii">
        <div class="element name">{{ contact?.firstName }} {{ contact?.lastName }}</div>
        <div class="element email">{{ contact?.email || 'N/A' }}</div>
    </div>

    <div class="info menu">
        <app-button
            class="menu-btn"
            [buttonType]="'mat-icon-button'"
            [cdkMenuTriggerFor]="menuTemplate"
            [cdkMenuTriggerData]="{ contact }"
            [elementType]="1"
            [icon]="'menu'"
            tooltip="Manage This Contact"
        >
        </app-button>
    </div>

    <mat-divider></mat-divider>

</ng-template>

<ng-template #menuTemplate let-contact="contact">
    <div class="menu popup-menu contacts-menu" cdkMenu>
        <ng-container [ngTemplateOutlet]="menuBtnTemplate" [ngTemplateOutletContext]="{contact}"></ng-container>
    </div>
</ng-template>

<ng-template #menuBtnTemplate let-contact="contact">
    <app-button
        class="menu-item"
        [buttonType]=""
        [elementType]="0"
        [icon]="'person'"
        routerLink="/users/contacts/{{contact.id||contact._id}}"
        text="View Contact"
        tooltip="View Full Contact Record"
    >
    </app-button>

    <app-button
        class="menu-item"
        [buttonType]=""
        [elementType]="0"
        [icon]="'schedule_send'"
        text="Schedule A Rendezvous"
        tooltip="Schedule A Meeting With This Contact"
        (click)="goToAddPlan(contact)"
    >
    </app-button>

    <app-button
        class="menu-item"
        [buttonType]=""
        [elementType]="0"
        [icon]="'manage_accounts'"
        routerLink="/users/contacts/edit/{{contact.id||contact._id}}"
        text="Edit Contact"
        tooltip="Edit This Contact"
    >
    </app-button>
</ng-template>