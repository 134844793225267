import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../interfaces/api-response';
import { environment } from '../../../environments/environment';
import { Contact } from '../interfaces/contacts';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http: HttpClient) {}

  async create(contactDetails: any) {
    return await this.http.put<ApiResponse<any>>(environment.endpoints.contacts.uri+'/', contactDetails);
  }

  async find(ids: string[]) {
    return await this.http.post<ApiResponse<Contact[]>>(environment.endpoints.contacts.uri+'/', {ids});
  }

  async list() {
    return await this.http.get<ApiResponse<Contact[]>>(environment.endpoints.contacts.uri+'/');
  }
}
