import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-place-contact',
  standalone: true,
  imports: [NgIf],
  templateUrl: './place-contact.component.html',
  styleUrl: './place-contact.component.scss'
})
export class PlaceContactComponent implements OnInit {
  @Input() address: string | undefined = "";
  @Input() directionsUrl: string | undefined = "";
  @Input() phone: string | undefined = "";
  @Input() plusCode: string | undefined = "";
  @Input() websiteUrl: string | undefined = "";

  hasOptions = false;

  ngOnInit(): void {
    this.hasOptions =
      !!this.address ||
      !!this.directionsUrl ||
      !!this.phone ||
      !!this.plusCode ||
      !!this.websiteUrl;
  }
}
