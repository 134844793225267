import * as Sentry from '@sentry/angular-ivy';
import { NgTemplateOutlet } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { AssistantChatObject } from 'src/app/common/interfaces/assistant';
import { PlaceListComponent } from "../place-list/place-list.component";
import { SearchService } from 'src/app/common/services/search.service';
import { MatIconModule } from '@angular/material/icon';
import { Place } from '../../common/interfaces/place';
import { AssistantChatObjectTypesKey } from 'src/app/common/enums/assistant';
import { Contact } from 'src/app/common/interfaces/contacts';
import { ContactsService } from 'src/app/common/services/contacts.service';
import { ListContactsComponent } from "../../modules/users/contacts/components/list-contacts/list-contacts.component";
import { ListMeetingsComponent } from "../../modules/users/meeting/components/list-meetings/list-meetings.component";
import { ListTripsComponent } from "../../modules/trips/components/list-trips/list-trips.component";
import { MeetingService } from 'src/app/common/services/meeting.service';
import { TripService } from 'src/app/common/services/trip.service';
import { Trip } from 'src/app/common/interfaces/trips';
import { Meeting } from 'src/app/common/interfaces/meeting';

@Component({
  selector: 'app-object-renderer',
  standalone: true,
  imports: [
    MatIconModule,
    NgTemplateOutlet,
    PlaceListComponent,
    ListContactsComponent,
    ListMeetingsComponent,
    ListTripsComponent
],
  templateUrl: './object-renderer.component.html',
  styleUrls: ['./object-renderer.component.scss']
})
export class ObjectRendererComponent implements OnInit {

  @Input() object!: AssistantChatObject;
  @Input() status = 'idle';

  places: Place[] = [];
  placesLoaded = false;
  placesService = inject(SearchService);

  contacts: Contact[] = [];
  contactsLoaded = false;
  contactsService = inject(ContactsService);

  trips: Trip[] = [];
  tripsLoaded = false;
  tripsService = inject(TripService);

  meetings: Meeting[] = [];
  meetingsLoaded = false;
  meetingsService = inject(MeetingService);

  objectsLoaded = false;
  objectsLoading = false;
  objectsLoadFailed = false;

  ngOnInit(): void {
    if (!this.object) {
      console.error('ObjectRendererComponent: Input object is not provided.');
    } else {
      switch (this.object.type) {
        case 'contact':
        case 'contactList':
          this.onObjectsLoading(this.object.type);
          this.getContacts().then(() => {
            this.onObjectsLoaded(this.object.type);
          }).catch((err) => {
            console.error(err);
          });
        break;
        case 'place':
        case 'placeList':
          this.onObjectsLoading(this.object.type);
          this.getPlaces().then(() => {
            this.onObjectsLoaded(this.object.type);
          });
        break;
        case 'meeting':
        case 'meetingList':
          this.onObjectsLoading(this.object.type);
          this.getMeetings().then(() => {
            this.onObjectsLoaded(this.object.type);
          });
        break;
        case 'trip':
        case 'tripList':
          this.onObjectsLoading(this.object.type);
          this.getTrips().then(() => {
            this.onObjectsLoaded(this.object.type);
          });
        break;
        case 'status':
          this.onObjectsLoading(this.object.type);
          this.onObjectsLoaded(this.object.type);
        break;
        default:
          console.log('ObjectRendererComponent initialized with unsupported object:', this.object);
        break;
      }
    }
  }

  async getContacts(): Promise<Contact[]> {
    let _this = this;
    return new Promise(async (resolve, reject) => {
      let request = await this.contactsService.find(this.object.ids);
      request.subscribe({
        next: (result) => {
          _this.contacts = result.data as Contact[];
          resolve(result.data as Contact[]);
        }, error: (err) => {
          Sentry.captureException(err);
          reject(err);
        }
      });
    });
  }

  getObjectTypeName(type: AssistantChatObjectTypesKey) {
    switch (type) {
      case 'contact':
      case 'event':
      case 'meeting':
      case 'place':
      case 'trip':
      case 'url':
        return 'Record';
      case 'contactList':
      case 'eventList':
      case 'meetingList':
      case 'placeList':
      case 'tripList':
      case 'urlList':
        return 'Lists';
      default:
        return 'Record(s)';
    }
  }

  async getPlaces() {
    let result = await this.placesService.getPlaces(this.object.ids);
    this.places = result.places;
  }

  async getMeetings() {
    let _this = this;
    return new Promise(async (resolve, reject) => {
      this.meetingsService.find(this.object.ids)
        .subscribe({next: (result) => {
          if (!result) { return; }
          _this.meetings = result.data as Meeting[];
          resolve(result.data as Meeting[]);
        },
        error: (err) => {      
          Sentry.captureException(err);
          reject(err);
        }
      });
    });
  }

  async getTrips() {
    let _this = this;
    return new Promise(async (resolve, reject) => {
      this.tripsService.find(this.object.ids)
        .subscribe({next: (result) => {
          if (!result) { return; }
          _this.trips = result;
          resolve(result);
        }, error: (err) => {
          Sentry.captureException(err);
          reject(err);
        }
      });
    });
  }

  onObjectsLoaded(type: string) {
    if (type === 'place' || type === 'placeList') {
      this.placesLoaded = true;
    } else if (type === 'contact' || type === 'contactList') {
      this.contactsLoaded = true;
    } else if (type === 'trip' || type === 'tripList') {
      this.tripsLoaded = true;
    } else if (type === 'meeting' || type === 'meetingList') {
      this.meetingsLoaded = true;
    }
    this.objectsLoaded = true;
    this.objectsLoading = false;
  }

  onObjectsLoading(type: string) {
    if (type === 'place' || type === 'placeList') {
      this.placesLoaded = false;
    } else if (type === 'contact' || type === 'contactList') {
      this.contactsLoaded = false;
    } else if (type === 'trip' || type === 'tripList') {
      this.tripsLoaded = false;
    } else if (type === 'meeting' || type === 'meetingList') {
      this.meetingsLoaded = false;
    }
    this.objectsLoaded = false;
    this.objectsLoading = true;
  }

  onObjectsLoadingError(type: string) {
    if (type === 'place' || type === 'placeList') {
      this.placesLoaded = false;
    } else if (type === 'contact' || type === 'contactList') {
      this.contactsLoaded = false;
    } else if (type === 'trip' || type === 'tripList') {
      this.tripsLoaded = false;
    } else if (type === 'meeting' || type === 'meetingList') {
      this.meetingsLoaded = false;
    }
    this.objectsLoaded = false;
    this.objectsLoading = false;
    this.objectsLoadFailed = true;
  }
}
