
<article
    class="notification {{customClass}}"
    [@fadeOut]="visibility"
    >
    @if (showCloseButton) {
    <app-button
        class="delete"
        color="basic"
        icon="close"
        buttonType="mat-icon-button"
        (click)="close()"
    ></app-button>
    }
    <div class="media">
        <div class="media-left">
            <span class="icon is-large" aria-hidden="">
                <mat-icon>{{icon}}</mat-icon>
            </span>
        </div>
        <div class="media-content">
            @if(message) {
            <div class="message">{{ message }}</div>
            } @else if (contentProjection) {
            <ng-content></ng-content>
            }
        </div>
    </div>
</article>
