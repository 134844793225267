import { inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Marked } from '@ts-stack/markdown';
import { PlaceTypeIcon, PlaceTypeIconKeyType } from '../enums/place';

export interface ParseOptions {
  keepLinks?:boolean,
  showIcon?:boolean,
  sanitize?:boolean,
}

@Injectable({
  providedIn: 'root'
})
export class MarkdownService {
  sanitizer = inject(DomSanitizer);
  constructor() { }
  parse(markdown:string, options:ParseOptions = {keepLinks: true, showIcon: true, sanitize: true}) {
    if(markdown){
      let content = this.parseMarkdownToMarkup(markdown);
      return this.getContent(content, options);
    }
    return '';
  }
  getContent(_content: (string | {
    mode: string;
    id: string;
    name: string;
    type: string;
  })[], options:ParseOptions = {keepLinks: true, showIcon: true, sanitize: true}): string {
    let content = '';
    for (var item of _content) {
      if(this.isString(item)) {
        content+= item;
      } else {
        if(!options?.keepLinks) {
          content+= ' '+this.getItemText(item);
        } else {
          let iconMarkup = options?.showIcon ? this.getIconMarkup(item) : '';
          content+= ` <a class="icon-link rich-link" routerLink="${this.getItemLink(item)}" href="${this.getItemLink(item)}" target="_blank">${iconMarkup}${this.getItemText(item)}</a>`
        }
      }
    }
    return options?.sanitize ? this.sanitize(Marked.parse(content)) : Marked.parse(content);
  }
  getIconMarkup(item: any): string {
    let _type = !item.type ? '' : (item.type?.endsWith('_restaurant') ? 'restaurant' : item.type);
    if(!_type) {return '';}
    let _icon = PlaceTypeIcon[_type as PlaceTypeIconKeyType];
    if(!_icon) {return '';}
    return `<span class="material-symbols-outlined">${_icon}</span>&nbsp;`;
  }
  getItemLink(item:any) {
    return `/place/${item?.id}`;
  }
  getItemText(item:any) {
    return item?.name;
  }
  isString(str:any) {
    return 'string' == typeof str;
  }
  parseMarkdownToMarkup(markdown:string) {
    let searchReplace = [
      ['**[::','[::'],
      [']::**','[::'],
      ['[::','@@@[::'],
      ['::]','::]@@@'],
      ['\\n',`<br/>`],
    ];
    searchReplace.forEach(arr => {markdown = markdown.replaceAll(arr[0], arr[1]);});
    return markdown.split('@@@').map((item) => {
      return item.startsWith('[::') ? this.processItem(item) : item;
    });
  }
  processItem(item: string) {
    const parts = item.replaceAll('[::','').replaceAll('::]','').split('::');
    const mode = parts[0] ?? null;
    const id = parts[1] ?? null;
    const name = parts[2] ?? null;
    const type = parts[3] ?? null;
    return {mode, id, type, name};
  }
  sanitize(html: any): any {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(html));
  }
}
