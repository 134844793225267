import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, debounce, map, of, shareReplay } from 'rxjs';
import { ApplicationState } from '../interfaces/application-state';

const initialState:ApplicationState = {viewport: { classes: [], size: ''}};

@Injectable({
  providedIn: 'root'
})
export class ViewportSizeService {

  private readonly store$ = new BehaviorSubject<ApplicationState>(initialState);
  private isNotDesktop$ = new BehaviorSubject<boolean>(true);
  private breakpointObserver = inject(BreakpointObserver);
  private formFactor = '';
  private sizeFactor = '';
  
  public viewportClasses$ = this.store$.pipe(map((state) => state.viewport?.classes as string[]));

  public isXLarge$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XLarge)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public isLarge$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Large)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public isMedium$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Medium)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public isSmall$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Small)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public isXSmall$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XSmall)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public isDesktop$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Web)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  // isHandset = true;
  // public isHandset$: Observable<boolean> = this.isNotDesktop$.asObservable().pipe(debounce(i => of(i === this.isHandset)));

  public isTablet$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
      .pipe(
        map(result => result.matches),
        shareReplay()
      );

  constructor() {

    this.isHandset$.subscribe((form) => {
      if(!form) {return;}
      // console.log('Handset Form Factor');
      this.formFactor = 'handset';
      this.updateVars();
    });

    this.isTablet$.subscribe((form) => {
      if(!form) {return;}
      // console.log('Tablet Form Factor');
      this.formFactor = 'tablet';
      this.updateVars();
    });

    this.isDesktop$.subscribe((form) => {
      if(!form) {return;}
      // console.log('Desktop Form Factor');
      this.formFactor = 'desktop';
      this.updateVars();
    });

    this.isXSmall$.subscribe((form) => {
        if(!form) {return;}
        // console.log('XSmall Screen');
        this.sizeFactor = 'xsmall';
        this.updateVars();
    });

    this.isSmall$.subscribe((form) => {
        if(!form) {return;}
        // console.log('Small Screen');
        this.sizeFactor = 'small';
        this.updateVars();
    });
  
    this.isMedium$.subscribe((form) => {
      if(!form) {return;}
      // console.log('Medium Screen');
      this.sizeFactor = 'medium';
      this.updateVars();
    });

    this.isLarge$.subscribe((form) => {
      if(!form) {return;}
      // console.log('Large Screen');
      this.sizeFactor = 'large';
      this.updateVars();
    });

    this.isXLarge$.subscribe((form) => {
      if(!form) {return;}
      // console.log('XLarge Screen');
      this.sizeFactor = 'xlarge';
      this.updateVars();
    });
  }

  private updateVars() {
    let nextValue = {
      ...this.store$.value,
      viewport: {
        classes: [this.formFactor, this.sizeFactor],
      },
    };
    this.store$.next(nextValue);
  }
}
