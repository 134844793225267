import { NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { OpeningHours } from 'src/app/common/interfaces/place';

@Component({
  selector: 'app-place-schedule',
  standalone: true,
  imports: [NgIf, NgForOf],
  templateUrl: './place-schedule.component.html',
  styleUrl: './place-schedule.component.scss'
})
export class PlaceScheduleComponent implements OnInit {
  @Input() currentOpeningHours: OpeningHours | undefined;
  @Input() regularOpeningHours: OpeningHours | undefined;
  hasSchedule = false;

  ngOnInit(): void {
    if(!this.currentOpeningHours) {this.currentOpeningHours = this.regularOpeningHours;}
    this.hasSchedule = !!this.currentOpeningHours;
  }
}
