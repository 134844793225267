import * as _ from 'lodash';
import * as Sentry from "@sentry/angular-ivy";
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlaceResult, LookupResult, PlacesResult } from '../interfaces/search';
import { ApplicationState } from '../interfaces/application-state';
import { Place } from '../interfaces/place';
import { StateService } from './state.service';
import { ApiResponse } from '../interfaces/api-response';

const defaultState: ApplicationState = {
  lookup: {
    lastSearchKey: '',
    places: new Map<string, Place>(),
    results: new Map<string, LookupResult>(),
    searchData: {
      additionalLocations: '',
      organizerLocation: '',
      type: '',
    },
  }
};

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private stateService = inject(StateService);

  constructor(private http: HttpClient) {}

  findInCache(id: string): Place | false {

    const state = this.stateService.getState() || defaultState;

    // Attempt to return from place details cache.
    let places = state?.lookup?.places as {[key:string]: any} || {};
    if(!!places && places[id] && (places[id]?.id || places[id]?._id)) {
      return places[id];
    }

    // Attempt to return from results of previous searches.
    let place: Place | undefined;
    let previousSearches = state?.lookup?.results as {[key:string]: any} || {};
    Object.keys(previousSearches)?.forEach((key) => {
      if(place) {return;}
      const previousPlaces: LookupResult = previousSearches[key];
      place = previousPlaces?.places?.find && previousPlaces.places.find(place => {return id == place?.id});
    });

    return place ?? false;
  }

  async getPlace(id: string) {

    // Look for place in cache.
    let place = this.findInCache(id);

    // If not found, fetch from external API and save in place details cache.
    if(!place || (place && (!place?.id && !place?._id))) {
      try {
        place = await this.findById(id);
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error fetching '+id, error);
      }

      if(place) {
        this.updateStateWithNewPlace(place);
      } else {
        Sentry.captureMessage('PLACE_NOT_FOUND: '+id);
      }
    }

    return place ?? undefined;
  }

  async getPlaces(ids: string[]): Promise<import("../../common/interfaces/search").PlacesResult> {
    let idsToFetch: string[] = [];
    let places:Place[] = [];
    let notFound:string[] = [];
    ids.forEach(id => {
      let place = this.findInCache(id);
      if(place) {
        places.push(place);
      } else {
        idsToFetch.push(id);
      }
    });
    if(!idsToFetch.length) {
      return {places, notFound};
    }
    let search = await this.http.post<ApiResponse<PlacesResult>>(environment.endpoints.search.uri+'/list', {ids: idsToFetch});
    await search.forEach(result => {
      if(result.success && result?.data?.places) {
        places = places.concat(result.data?.places);
        notFound =  notFound.concat(result.data?.notFound);
        result.data.places.forEach(place => {
          this.updateStateWithNewPlace(place);
        });
      }
    });
    return {places, notFound};
  }

  async getPlaceImage(id: string): Promise<string | undefined> {
    return (await this.findById(id)).image?.photo.photoUri
  }

  async lookup(searchData: any) {

    let date = searchData?.date;
    let time = searchData?.time;
    delete searchData.date;
    delete searchData.time;
    const searchKey = this.getSearchKey(searchData);
    this.updateLatestSearch(searchKey, searchData);

    const state = this.stateService.getState();
    let previousSearches = state?.lookup?.results as {[key:string]: any};
    if(previousSearches && previousSearches[searchKey]) {
      const previousSearch:LookupResult = previousSearches[searchKey];
      if(previousSearch?.places?.length) {
        return of(previousSearch);
      }
    }

    searchData = {...searchData, date, time};
    const request = this.http.post<ApiResponse<LookupResult>>(environment.endpoints.search.uri+'/lookup', searchData);

    let results:LookupResult = {
      midpoint: {
        latitude: 0,
        longitude: 0
      },
      places: [{}]
    };

    await request.forEach((result) => {
      if(result.success && result.data) {
        results = result.data;
        previousSearches[searchKey] = result.data;
        if(!state.lookup) {state.lookup = {results: []}}
        state.lookup.results = previousSearches;
        this.stateService.setState(state);
      } else {
        //@todo: Handle any error responses that are not caught as errors.
      }
    }).catch(err => {
      //@todo: Handle any error responses.
      console.log('Error: ', err);
    });

    return of(results);
  }

  async findById(id: string) {

    let place:Place = {};
    let search = await this.http.get<ApiResponse<PlaceResult>>(environment.endpoints.place.uri+'/'+id);
    await search.forEach(result => {
      if(!result.success || !result?.data?.place) {
        //@todo: Implement erorr handling for failed place findById results.
        return;
      }
      place = result.data.place
    });

    return place;
  }

  getSearchKey(arg0: object) {
    const keys = Object.keys(arg0);
    let searchKey = '';
    let keyPrep = function(key:any): string {
      return null === key || false === key || true === key ? '' :  'number' == typeof key ? key : (Array.isArray(key) ? keyPrep(key.join('')) : key.replace(/[\W_]+/g,""));
    };
    for(var i = 0; i < keys.length; i++) {
      searchKey+= keyPrep(arg0[keys[i] as keyof object]);
    }
    return searchKey;
  }

  updateLatestSearch(lastSearchKey: string, searchData:{} = {}) {
    const currentState = this.stateService.getState();
    const newState = {
      ...currentState,
      lookup: {
        lastSearchKey,
        results: currentState?.lookup?.results || {},
        searchData,
      }
    };

    this.stateService.setState(newState)
  }

  updateStateWithNewPlace(place: Place) {
    const state = this.stateService.getState();
    let places = state?.lookup?.places as {[key:string]: any} || {};
    places[place.id as string || place._id as string] = place;
    const newState: ApplicationState = {
      ...state,
      lookup: {
        places,
        results: state?.lookup?.results || new Map<string, LookupResult>()
      },
    };
    this.stateService.setState(newState);
  }
}
