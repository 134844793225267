import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormErrors } from 'src/app/common/enums/content';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AutofillEvent } from '@angular/cdk/text-field';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTooltip,
    NgIf,
    NgForOf,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    }
  ],
})
export class FormInputComponent implements OnInit {

  /**
   * The input style class.
   */
  @Input() class: any;

  /**
   * The placeholder to display.
   */
  @Input() placeholder!: string;

  /** The name of the control. */
  @Input() name!: string | number | any;
  /** The reactive formControlName property of the control. */
  @Input() formControlName: any;
  @Input() autocomplete: any;
  @Input() autoCompleteOptions?: any;
  @Input() disabled: boolean = false;
  @Input() fill = 'fill';
  @Input() id = '';
  @Input() inputControl!: FormControl;
  @Input() hint!: string;
  @Input() label!: string;
  @Input() maxlen!: number;
  @Input() required: boolean = false;
  @Input() order!: number;
  @Input() type!: string;
  @Input() value!: string;
  @Input() errors!: {[key: string]: string;};
  @Input() prefixIcon!: string;
  @Input() helpTooltip!: string;
  @Input() helpTooltipIcon!: string;
  @Input() formGroup!: FormGroup<any>;

  /** Emits an event whenever the `ENTER` key is pressed. */
  @Output() enterKeyPressed = new EventEmitter();

  /** Emits an event whenever the value of the input changes. */
  @Output() valueChanged = new EventEmitter();

  autocompleteDisabled = true;
  msgInvalidEmail = FormErrors.invalid_email;
  msgRequired = FormErrors.required;

  constructor() {}

  ngOnInit(): void {
    if(!this.value) {this.value = '';}
    this.buildErrors();
    if (!this.formGroup) {
      this.formGroup = new FormGroup<any>({
        [this.formControlName]: this.inputControl
      });
    }
  }

  buildErrors() {
    let defaultErrors = {
      email: FormErrors.invalid_email,
      invalid_email: FormErrors.invalid_email,
      maxlength: FormErrors.maxlength,
      minlength: FormErrors.minlength,
      password_mismatch: FormErrors.password_mismatch,
      pattern: FormErrors.pattern,
      required: FormErrors.required,
      required_type: FormErrors.required_type,
    };
    if(!this.errors) {this.errors = {};}
    this.errors = {...defaultErrors, ...this.errors};
  }

  isValid() {
    return this.inputControl?.valid;
  }

  onAutoCompletion($event: MatAutocompleteSelectedEvent) {
    let value = $event.option.value;
    if(this.inputControl.value !== value) {
      this.inputControl.setValue(value);
    }
    this.onChanged(value);
  }

  onAutoFill($event: AutofillEvent) {
    console.log('onAutoFill', $event);
    // let value = $event.isAutofilled ? $event.target.nodeValue : this.inputControl.value;
    // if(this.inputControl.value !== value) {
    //   this.inputControl.setValue(value);
    // }
    // this.onChanged(value);
  }

  onBlur($event: FocusEvent) {
    this.autocompleteDisabled = true;
  }

  onChange = (_: any) => {}

  onChanged = (newValue: Event|any) => {
    this.value = newValue;
    this.valueChanged.emit(newValue);
  }

  onFocus($event: FocusEvent) {
    this.autocompleteDisabled = false;
  }

  onKeyPress($event: KeyboardEvent) {
    if(-1 !== [$event?.code.toLowerCase(), $event?.key?.toLowerCase()].indexOf('enter')) {
      $event.stopPropagation();
      $event.stopImmediatePropagation();
      $event.preventDefault();
      this.enterKeyPressed.emit($event);
    }
  }
  
  onKeyUp($event: KeyboardEvent) {
    let currentValue = this.inputControl?.value;
    if(currentValue !== this.value) {
      this.onChanged(currentValue);
    }
  }

  onNgModelChange($event: any) {
    this.onChanged($event);
  }

  writeValue(val: any): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
