import * as moment from 'moment';
import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDividerModule } from '@angular/material/divider';
import { BehaviorSubject, Observable, Subject, concatAll, debounceTime, switchMap } from 'rxjs';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ButtonColor, ButtonType } from '../../../../../common/enums/button';
import { ApiResponse } from '../../../../../common/interfaces/api-response';
import { AssistantChat, AssistantChatResponse } from '../../../../../common/interfaces/assistant';
import { AjaxUiNoticeType, AjaxUxHandlerService } from '../../../../../common/services/ajax-ux-handler.service';
import { AssistantService } from '../../../../../common/services/assistant.service';
import { CryptoService } from '../../../../../common/services/crypto.service';
import { MarkdownService } from '../../../../../common/services/markdown.service';
import { SpeechService } from '../../../../../common/services/speech.service';
import { ButtonComponent } from '../../../../../components/button/button.component';
import { FormInputComponent } from '../../../../../components/form-input/form-input.component';
import { NoticeComponent } from "../../../../../components/notice/notice.component";
import { ObjectRendererComponent } from "../../../../../components/object-renderer/object-renderer.component";
import { SocketService } from 'src/app/common/services/socket.service';
import { SocketEvents } from 'src/app/common/enums/events';

@Component({
    selector: 'app-prompt-interface',
    standalone: true,
    templateUrl: './prompt-interface.component.html',
    styleUrl: './prompt-interface.component.scss',
    imports: [
    AsyncPipe,
    ButtonComponent,
    FormInputComponent,
    MatDividerModule,
    MatIcon,
    MatProgressSpinner,
    NgForOf,
    NgIf,
    ScrollingModule,
    NgTemplateOutlet,
    NoticeComponent,
    ObjectRendererComponent
]
})
export class PromptInterfaceComponent implements OnInit {

  private request$ = new Subject();
  private assistantService = inject(AssistantService);
  private subject = new BehaviorSubject<AssistantChat[]>([]);

  @Input() appearance: 'chatbot'|'assistant' = 'assistant';
  @Input() autosize = false;
  @Input() mode: 'trip-planner'|'general-purpose' = 'general-purpose';
  @Input() data: {[key:string]: any;} | null = null;
  /** Unique identifier (process ID) for this instance of the component as multiple chats (components) could be instantiated at any given time by the same user. */
  @Input() pid: string = CryptoService.getRandomAlphaNumeric();
  @ViewChild(NoticeComponent) notice!:NoticeComponent;

  messages$: Observable<AssistantChat[]> = this.subject.asObservable();
  messages:AssistantChat[] = [];

  router = inject(Router);
  speech = inject(SpeechService);
  markup = inject(MarkdownService);
  socket = inject(SocketService);
  fb = new FormBuilder();
  form = this.fb.group({prompt: ['',],});
  latestMessageId:string|null = null;
  promptCtrl!: FormControl<any>;

  buttons = {
    cancel: {
      color: ButtonColor.warn,
      disabled: false,
      text: 'CANCEL',
      type: ButtonType.mini_fab
    },
    submit: {
      color: ButtonColor.primary,
      disabled: false,
      text: 'SUBMIT',
      type: ButtonType.raised
    },
  };
  classList:string[] = ['prompt-interface'];
  requestIsActive = false;
  status = 'Idle';
  showStatus = false;
  time = this.getTime();

  noticeMessage = '';
  noticeType: "error"|"info"|"warning" = "info";
  showNoticeMessage = false;
  voiceCaptureActive = false;

  addMessages(newMessages: AssistantChat[]) {
    this.latestMessageId = newMessages[0]?.id;
    const messages = newMessages.concat(this.messages);
    this.messages = messages.map((message) => {
      if(message?.content?.length) {
        let parsedContent = '';
        for (let index = 0; index < message.content.length; index++) {
          const _content = message.content[index];
          let responses = 'text' == _content?.type && _content?.text?.value?.response;
          for (let index2 = 0; responses && index2 < responses.length; index2++) {
            const response = responses[index2];
            if(response && 'displayText' == response?.action) {
              response?.data.content.forEach((responseContent) => {
                parsedContent+= this.markup.parse(responseContent);
              });
            } else if(response && 'renderObject' == response?.action) {
              if(!message.renderObjects) {message.renderObjects = [];}
              message.renderObjects.push({type: response?.data.type, ids: response?.data?.content, contentIndex: index2});
              console.log('Handle action: ', response);
            }
          }
        }
        message.parsedContent = parsedContent;
      }
      return message;
    });
    this.subject.next(this.messages);
  }

  cancelRun(event: any) {
    event.stopPropagation();
    this.onRequestEnded();
    this.router.navigateByUrl('/dashboard', {
      onSameUrlNavigation: 'reload'
    });
  }

  getMessageDate(timeSincEpocInSeconds: number) {
    const timeSincEpocInMilli = timeSincEpocInSeconds * 1000;
    switch (true) {
      case timeSincEpocInMilli > this.time.fiveMinsAgo:
        return moment.duration(moment(timeSincEpocInMilli).diff(moment())).humanize(true);

      case timeSincEpocInMilli >= this.time.yesterday:
        return moment(timeSincEpocInMilli).format('h:mm A');

      case timeSincEpocInMilli >= this.time.twoDaysAgo:
        return 'Yesterday, ' + moment(timeSincEpocInMilli).format('h:mm A');

      case timeSincEpocInMilli >= this.time.lastYear:
        return moment(timeSincEpocInMilli).format('Do MMM YYYY, h:mm A');

      default:
        return moment(timeSincEpocInMilli).format('Do MMM, h:mm A');
    }
  }

  getMessageRole(role: string) {
    return  'user' == role ? 'Me' : 'Assistant';
  }

  getTime() {
    return {
      now: moment.now(),
      fiveMinsAgo: moment().subtract(5, 'minutes').valueOf(),
      lastYear:  moment().startOf('year').valueOf(),
      twoDaysAgo: moment().startOf('day').subtract(1, 'day').valueOf(),
      yesterday: moment().startOf('day').valueOf(),
    };
  }

  initAssistant() {
    this.status = 'Initializing assistant...';
    this.assistantService.initializeAssistant(this.mode, this.data).subscribe(stream => this.processMessageStream(stream));
    this.renderAssistantStatus(this.status);
  }

  initCssClasses() {
    if(this.autosize) {this.classList.push('autosize');}
    this.classList.push('mode-'+this.mode);
    this.classList.push('appearance-'+this.appearance);
  }

  initForm() {
    this.promptCtrl = this.form.get('prompt') as unknown as FormControl<any>;
    this.request$.pipe(
      debounceTime(500),
      switchMap(async (prompt: any) => {
        this.status = 'Sending request...';
        this.onRequestActive();
        return this.assistantService.createPrompt(prompt, this.mode, this.latestMessageId as string, this.data);
      })
    )
    .pipe(concatAll())
    .subscribe({next: stream => this.processMessageStream(stream), error: (error) => {
      console.log('PROMPT ERROR: ', error);
      this.onRequestEnded(error);
    }});
  }

  initSocket() {
    this.socket.registerListener(SocketEvents.promptInterfaceServerStatusUpdate, (data:any) => {
      if(data?.pid && data.pid != this.pid) {return;}
      this.status = data.status;
    });
  }

  ngOnInit(): void {
    if(!this.data) {this.data = {};}
    this.data['pid'] = this.pid;
    this.initCssClasses();
    this.initAssistant();
    this.initForm();
    this.initSocket();
  }

  onRequestActive() {
    this.buttons.submit.text = 'SENDING...';
    this.renderUnpersistedUserMessage(this.promptCtrl.value);
    this.renderAssistantStatus(this.status);
    AjaxUxHandlerService.onRequestActive(this, true);
  }

  onRequestEnded(error?:Error) {
    this.buttons.submit.text = 'SUBMIT';
    AjaxUxHandlerService.onRequestEnded(this, error);
  }

  async onStartSpeechRecording() {

    if(this.voiceCaptureActive) {
      this.speech.stopListening();
      this.voiceCaptureActive = false;
      return;
    }

    this.voiceCaptureActive = true;
    this.speech.startListening()
      .then(result => {
        if(result?.transcript) { 
          let _prompt = this.promptCtrl?.value;
          if(null == _prompt || undefined == _prompt) {
            _prompt = '';
          }
          this.promptCtrl.setValue((_prompt+' '+result.transcript).trim());
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(()=>{
        this.voiceCaptureActive = false;
      });
  }

  processMessageStream(stream: ApiResponse<AssistantChat[]>) {
    AjaxUxHandlerService.onRequestEnded(this, stream?.error);
    if(stream.success) {
      if(this.showStatus && this.messages[0]?.renderObjects[0]?.type == 'status') {
        this.messages.shift();
        this.showStatus = false;
      }
      this.addMessages(stream?.data as AssistantChat[]);
    }
  }

  refreshView() {
    this.latestMessageId = null;
    this.time = this.getTime();
  }

  renderResponseAction(response: AssistantChatResponse) {
    switch (response.action) {
      case 'displayText':
      default:
        return response?.data?.content.reduce((prev, curr) => `${prev}\n${curr}`);
    }
  }

  renderUnpersistedUserMessage(prompt: string) {
    this.addMessages([{
      id: 'user_message_' + CryptoService.getRandomAlphaNumeric({ length: 4 }),
      content: [{
        type: 'text',
        text: {
          value: {
            response: [{
              action: 'displayText',
              data: {
                id: 'user_message_' + CryptoService.getRandomAlphaNumeric({ length: 4 }),
                interaction: 'view',
                type: 'text',
                content: [prompt]
              }
            }]
          }
        }
      }],
      role: 'user',
      parsedContent: prompt,
      renderObjects: [],
      created_at: new Date().getTime() / 1000,
    }]);
  }

  renderAssistantStatus(status: string) {
    this.addMessages([{
      id: 'assistant_status_' + CryptoService.getRandomAlphaNumeric({ length: 4 }),
      content: [{
        type: 'text',
        text: {
          value: {
            response: [{
              action: 'renderObject',
              data: {
                id: 'assistant_status_' + CryptoService.getRandomAlphaNumeric({ length: 4 }),
                interaction: 'view',
                type: 'status',
                content: [status]
              }
            }]
          }
        }
      }],
      role: 'assistant',
      parsedContent: status,
      renderObjects: [],
      created_at: new Date().getTime() / 1000,
    }]);
    this.showStatus = true;
  }

  resendPrompt(message: AssistantChat) {
    let value = message.content[0].text.value.response[0]?.data.content[0];
    this.promptCtrl.setValue(value);
    this.submitForm();
  }

  showNotice(_notice: string, _type:AjaxUiNoticeType) {
    throw new Error('Not yet implemented');
  }

  submitForm() {
    if (AjaxUxHandlerService.canSubmitForm(this)) {
      const command = this.promptCtrl.value;
      this.request$.next(command);
    } else {
      AjaxUxHandlerService.onInvalidFormSubmission(this);
    }
  }
}
