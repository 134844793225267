import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[dynamicBgColor]',
  standalone: true
})
export class DynamicBgColorDirective implements OnChanges {
  /** When supplied, it uses this string to generate a consistent color everytime, otherwise generated color is random. */
  @Input() dynamicBgColor!: string;
  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dynamicBgColor']) {
      const color = this.dynamicBgColor ? this.strToHex(this.dynamicBgColor) : this.getRandomColor();
      this.changeBackgroundColor(color);
    }
  }

  private changeBackgroundColor(color: string): void {
    this.el.nativeElement.style.backgroundColor = color;
    this.el.nativeElement.style.color = this.getContrastingColor(color);
  }

  /**
   * Calculates and returns the contrast color (black or white) based on the input hex color.
   * @param hex - The input hex color code.
   * @returns The contrasting color code (either '#000000' for black or '#FFFFFF' for white).
   */
  getContrastingColor(hex: string): string {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
    // Parse the r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    // Calculate the brightness of the color
    let brightness = (r * 299 + g * 587 + b * 114) / 1000;
    // If brightness is greater than 128, return black, else return white
    return brightness > 128 ? '#000000' : '#FFFFFF';
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  strToHex(str: string) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    return '#' + ("00000".substring(0, 6 - c.length) + c);
  }
}