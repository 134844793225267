import * as Sentry from '@sentry/angular-ivy';
import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/common/services/snack-bar.service';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { TripService } from 'src/app/common/services/trip.service';
import { PlacePricingComponent } from "src/app/components/place-pricing/place-pricing.component";
import { PlaceStatusComponent } from "src/app/components/place-status/place-status.component";
import { RatingComponent } from "src/app/components/rating/rating.component";
import { PlaceContactComponent } from "src/app/components/place-contact/place-contact.component";
import { PlaceIconComponent } from 'src/app/components/place-icon/place-icon.component';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { Trip } from 'src/app/common/interfaces/trips';
import { PageHeaderComponent } from "../../../../components/page-header/page-header.component";

@Component({
  selector: 'app-list-trips',
  standalone: true,
  imports: [
    ButtonComponent,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatPaginator,
    MatTableModule,
    PlaceContactComponent,
    PlaceIconComponent,
    PlacePricingComponent,
    PlaceStatusComponent,
    RatingComponent,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: './list-trips.component.html',
  styleUrl: './list-trips.component.scss'
})
export class ListTripsComponent implements AfterViewInit, OnInit {

  @Input() paginatorOptions = {pageSize: 10, pageSizeOptions: [5, 10, 25, 100]};
  @Input() showCreateTripButton = true;
  @Input() showFilter = true;
  @Input() showPaginator = true;
  @Input() showTableHeaderTitle = true;
  @Input({required: true}) trips = [] as Trip[];

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable<Trip>) table!: MatTable<Trip>;

  columnsToDisplay = ['details'];
  dataSource: MatTableDataSource<Trip> = new MatTableDataSource([] as Trip[]);
  filterControl = new FormControl('', {nonNullable: true});
  filterValue = '';

  constructor(private snackbar: SnackBarService){}

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    if (this.showPaginator && this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getStatusName(status: string) {
    return status.charAt(0).toUpperCase() + status.substring(1);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.dataSource.data = this.trips.filter(trip => 'pending' != trip.status) || [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (trip: Trip, filter: string) => {
      let tests = [];
      tests.push(trip?.destination);
      tests.push(trip?.title);
      tests.push(trip?.origin);
      const regex = new RegExp(filter, 'gi');
      for (let index = 0; index < tests.length; index++) {
        if(regex.test(tests[index]!)) {return true}
      }
      return false;
    };
  }

  // removeBookmark(place: Trip) {
  //   // const _this = this;
  //   // this.bookmarker.delete({place: place._id || place.id!})
  //   // .then(_value => _value.subscribe({
  //   //   next: (_value: ApiResponse<any>) => {
  //   //     this.snackbar.show(place?.displayName?.text+' has been removed from your bookmarks.', 'UNDO', {
  //   //       next: () => { _this.bookmarkPlace(place);}
  //   //     }, {
  //   //       next: () => {
  //   //         _this.removePlaceFromDataset(place);
  //   //       }
  //   //     });
  //   //   },
  //   //   error: (err: any) => {
  //   //     Sentry.captureException(err);
  //   //   }
  //   // }));
  // }

  // removePlaceFromDataset(place: Trip) {
  //   // this.dataSource.data = this.dataSource.data.filter(_place => _place._id != place._id);
  //   // this.table.renderRows();
  // }

  trim(str: string) {
    return str.trim();
  }
}

