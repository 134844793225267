<div class="app-container {{ bodyClass }} {{ viewportClass }}">
  <mat-drawer-container [hasBackdrop]="true" class="drawer-container">
    <mat-drawer #drawer class="sidenav" fixedInViewport
        *ngIf="isHandset$ | async"
        [attr.role]="'navigation'"
        [mode]="'over'"
        [opened]="false">
      <app-navigation [viewportClass]="(isHandset$ | async) ? handsetViewportClass : desktopViewportClass"></app-navigation>
    </mat-drawer>
    <mat-drawer-content cdkScrollable (scroll)="onContainerScroll()">
      <!-- Toolbar -->
      <div class="toolbar" role="banner">
        <app-toolbar 
          [title]="title" 
          [isHandset]="isHandset$ | async" 
          [viewportClass]="(isHandset$ | async) ? handsetViewportClass : desktopViewportClass"
          (menuToggle)="toggleMenu()">
        </app-toolbar>
      </div>
      <div #content class="content" role="main">
        <router-outlet></router-outlet>
        <!-- Footer -->
        <footer>
        </footer>
      </div>
      <div *ngIf="isLoggedIn && (isHandset$ | async)" class="toolbar footer" role="toolbar">
        <app-toolbar 
          [isHandset]="isHandset$ | async" 
          [viewportClass]="(isHandset$ | async) ? handsetViewportClass : desktopViewportClass"
          [mode]="'footer'"
        >
        </app-toolbar>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <div class="background"></div>
</div>