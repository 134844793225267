import * as moment from 'moment';
import { CdkMenuModule } from '@angular/cdk/menu';
import { NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatIcon } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { Meeting } from 'src/app/common/interfaces/meeting';
import { MeetingService } from 'src/app/common/services/meeting.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { DynamicBgColorDirective } from 'src/app/common/directives/dynamic-bg-color.directive';
import { MatDivider } from '@angular/material/divider';

export interface MeetingParticipant {firstName: string, lastName:string, _id:string}

@Component({
    selector: 'app-list-meetings',
    standalone: true,
    templateUrl: './list-meetings.component.html',
    styleUrl: './list-meetings.component.scss',
    imports: [
      ButtonComponent,
      CdkMenuModule,
      DynamicBgColorDirective,
      MatDivider,
      MatFormField,
      MatIcon,
      MatInput,
      MatLabel,
      MatPaginator,
      MatTableModule,
      NgIf,
      ReactiveFormsModule,
      RouterModule,
    ],
    animations: [
      trigger('detailExpand', [
        state('collapsed,void', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
})
export class ListMeetingsComponent {

  /** Whether to show a particpants column in the main list view. */
  showParticpantsColInMainView = false;
  columnsToDisplay: string[] = ['scheduledTime','venue','status', 'participants',];
  columnsToDisplayWithExpand: string[] = [];
  dataSource: MatTableDataSource<Meeting> = new MatTableDataSource([] as Meeting[]);
  expandedElement!: any | null;
  filterControl = new FormControl('', {nonNullable: true});
  showLabel: any;
  time = this.getTime();

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private router: Router, private service: MeetingService){}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  cancelPlan(meeting: Meeting) {
    console.log(meeting);
  }

  getColumnName(column: string) {
    switch (column) {
      case 'scheduledTime':
        return 'Date/Time';
      default:
        return column.split(' ').map(w => w.charAt(0).toUpperCase() + ((w.length > 1) ? w.substring(1) : '')).join(' ');
    }
  }

  getInitials(participant: MeetingParticipant) {
    return (
      (participant?.firstName ? participant.firstName.charAt(0) : '')+
      (participant?.lastName ? participant.lastName.charAt(0) : '')
    ).trim()
  }

  getMeetingDate(date: string, useDefaultFormat=false) {
    const _date = moment(date, true);
    const timeSincEpocInMilli = _date.valueOf();
    switch (true) {
      case timeSincEpocInMilli >= this.time.today && timeSincEpocInMilli < this.time.tomorrow:
        return 'Today at ' + _date.format('h:mm A');
      case timeSincEpocInMilli < this.time.today && timeSincEpocInMilli >= this.time.yesterday:
        return 'Yesterday, ' + moment(timeSincEpocInMilli).format('h:mm A');
      case timeSincEpocInMilli >= this.time.tomorrow && timeSincEpocInMilli < this.time.twoDaysTime:
        return 'Tomorrow, ' + moment(timeSincEpocInMilli).format('h:mm A');
      case useDefaultFormat || timeSincEpocInMilli < this.time.thisYear || timeSincEpocInMilli >= this.time.nextYear:
        return moment(timeSincEpocInMilli).format('Do MMM YYYY, h:mm A');
      default:
        return moment(timeSincEpocInMilli).format('Do MMM, h:mm A');
    }
  }

  getMeetingVenue(venue: any) {
    return venue?.displayName?.text;
  }

  getParticipantName(participant: MeetingParticipant) {
    return (participant?.firstName+' '+participant?.lastName).trim();
  }

  getStatusName(status: string) {
    return status.charAt(0).toUpperCase() + status.substring(1);
  }

  getTime() {
    return {
      nextYear: moment().startOf('year').add(1, 'year').valueOf(),
      now: moment.now(),
      thisYear: moment().startOf('year').valueOf(),
      tomorrow: moment().startOf('day').add(1, 'day').valueOf(),
      today: moment().startOf('day').valueOf(),
      twoDaysTime:  moment().startOf('day').add(2, 'day').valueOf(),
      yesterday: moment().startOf('day').subtract(1, 'day').valueOf(),
    };
  }

  goToEditPlan(meeting: Meeting) {
    this.router.navigate( ['/users', 'planner', 'edit', meeting.id], {state: {meeting}});
  }

  ngOnInit(): void {
    if(!this.showParticpantsColInMainView) {
      this.columnsToDisplay = this.columnsToDisplay.filter(columnName => columnName != 'participants');
    }
    this.columnsToDisplayWithExpand = ['expand'].concat(this.columnsToDisplay);
    this.service.list()
    .subscribe({
      next: (value) => {
        this.dataSource.data = value.data || [];
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (meeting: Meeting, filter: string) => {
          let tests = [];
          if(meeting?.participants?.length) {
            tests.push(meeting?.participants?.reduce((prev, curr) => {
              return prev+(curr?.user?.firstName+curr?.user?.lastName!);
            }, ''));
          }
          tests.push(meeting?.venue?.displayName?.text);
          const regex = new RegExp(filter, 'gi');
          for (let index = 0; index < tests.length; index++) {
            if(regex.test(tests[index]!)) {return true}
          }
          return false;
        };
      },
      error: (err) => {
        console.log(err);
      },
    })
  }

  trim(str: string) {
    return str.trim();
  }
}
