<div *ngIf="hasOptions" class="vibe place-widget place-options-widget">
    <span class="title">Vibe</span>
    <ul class="options">
        <li *ngIf="allowsDogs as boolean" class="option">
            <span class="material-symbols-outlined">pets</span> 
            <span>Allows Dogs</span>
        </li>
        <li *ngIf="goodForChildren as boolean" class="option">
            <span class="material-symbols-outlined">child_care</span> 
            <span>Good for Children</span>
        </li>
        <li *ngIf="goodForGroups as boolean" class="option">
            <span class="material-symbols-outlined">groups</span> 
            <span>Good for Groups</span>
        </li>
        <li *ngIf="liveMusic as boolean" class="option">
            <span class="material-symbols-outlined">music_cast</span> 
            <span>Live Music</span>
        </li>
        <li *ngIf="outdoorSeating as boolean" class="option">
            <span class="material-symbols-outlined">deck</span> 
            <span>Outdoor Seating</span>
        </li>
        <li *ngIf="goodForWatchingSports as boolean" class="option">
            <span class="material-symbols-outlined">tv_signin</span> 
            <span>Watch Sports</span>
        </li>
    </ul>
</div>