import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BusinessStatus, BusinessStatusName, BusinessStatusNameKey } from 'src/app/common/enums/place';

@Component({
  selector: 'app-place-status',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './place-status.component.html',
  styleUrl: './place-status.component.scss'
})
export class PlaceStatusComponent implements OnInit {
  @Input() status!: BusinessStatusNameKey | undefined;
  hasStatus = false;
  statusText: any;

  ngOnInit(): void {
    this.hasStatus = !!this.status;
    this.statusText = !!this.status ? BusinessStatusName[this.status] : "";
  }

  getClass() {
    switch (this.status) {
      case BusinessStatus.BUSINESS_STATUS_UNSPECIFIED:
        return 'unspecified';
      case BusinessStatus.CLOSED_PERMANENTLY:
        return 'closed_permanently';
      case BusinessStatus.CLOSED_TEMPORARILY:
        return 'closed_temporarily';
      case BusinessStatus.OPERATIONAL:
        return 'operational';
      default:
        return "";
    }
  }
}
