<div class="prompt-interface"  [class]="classList">

    <div class="prompt-widget">

        <div class="prompt_input">

            <app-button
                *ngIf="!requestIsActive"
                [color]="voiceCaptureActive ? buttons.cancel.color : buttons.submit.color"
                [disabled]="requestIsActive"
                [icon]="voiceCaptureActive ? 'mic_off' : 'mic'"
                [outlinedIcons]="true"
                [text]="''"
                [tooltip]="voiceCaptureActive ? 'Speech Recognition In Progress...' : 'Use Voice Commands'"
                [type]="'submit'"
                [id]="'prompt_interface_submit_btn'"
                (click)="onStartSpeechRecording()"
                class="action-btn voice-command"
                [buttonType]="'mat-mini-fab'"
            ></app-button>

            <app-form-input #formField
                [formGroup]="form"
                [formControlName]="'prompt'"
                [inputControl]="promptCtrl"
                label="Enter a command, ask a question, make a request, etc."
                [required]="true"
                (enterKeyPressed)="submitForm()"
                class="prompt full-width"
            ></app-form-input>

            <app-button
                *ngIf="!requestIsActive"
                [color]="buttons.submit.color"
                [disabled]="buttons.submit.disabled"
                [icon]="'send'"
                [outlinedIcons]="true"
                [text]="''"
                [tooltip]="'Send Request'"
                [type]="'submit'"
                [id]="'prompt_interface_submit_btn'"
                (click)="submitForm()"
                class="action-btn submit-run"
                [buttonType]="'mat-mini-fab'"
            ></app-button>

            <mat-spinner
                *ngIf="requestIsActive"
                class="spinner"
                [diameter]="50">
            </mat-spinner>

            <app-button
                *ngIf="requestIsActive"
                [buttonType]="buttons.cancel.type"
                [color]="buttons.cancel.color"
                [disabled]="buttons.cancel.disabled"
                [icon]="'stop'"
                [outlinedIcons]="true"
                [tooltip]="'Attempt To Cancel Request'"
                [type]="'button'"
                [id]="'prompt_interface_cancel_btn'"
                (click)="cancelRun($event)"
                class="action-btn cancel-run"
            ></app-button>
        </div>
        
        <app-notice *ngIf="showNoticeMessage" #notice [type]="noticeType" [message]="noticeMessage"></app-notice>

    </div>

    <div class="chat_history">

        @if (autosize) {
        <cdk-virtual-scroll-viewport appendOnly itemSize="100" class="viewport messages">
            <div *cdkVirtualFor="let message of messages$ | async; let index=index;" class="item message {{message.role}}">
                <ng-container 
                    [ngTemplateOutlet]="messagesTemplate" 
                    [ngTemplateOutletContext]="{message:message, index:index}">
                </ng-container>
            </div>
        </cdk-virtual-scroll-viewport>
        } @else {
        <div class="viewport messages">
            <div *ngFor="let message of messages$ | async; let index=index;" class="item message">
                <ng-container 
                    [ngTemplateOutlet]="messagesTemplate" 
                    [ngTemplateOutletContext]="{message:message, index:index}">
                </ng-container>
            </div>
        </div>
        }
    </div>

</div>

<ng-template #messagesTemplate let-message="message" let-index="index">

    <div class="role center-contents-horizontally">
        <mat-icon class="role_icon" *ngIf="'assistant' == message.role">support_agent</mat-icon> 
        <mat-icon class="role_icon" *ngIf="'assistant' != message.role">face_5</mat-icon>
        <div>
            <span class="role_type">{{ getMessageRole(message.role) }}</span>
            <div class="timestamp">{{ getMessageDate(message.created_at) }}</div>
        </div>
    </div>

    @if (message.parsedContent) {
    <div class="content" [innerHTML]="message.parsedContent"></div>
    }

    @if (message.renderObjects && message.renderObjects.length) {
    <div class="content objects">
        <app-object-renderer *ngFor="let object of message.renderObjects" [object]="object" [status]="status"></app-object-renderer>
    </div>
    }

    @if ('assistant' != message.role && 0==index) {
    <div class="content resend">
        <app-button
            [icon]="{name: 'send', position: 'append'}"
            [text]="'Resend this message'"
            [tooltip]="'Resend this message'"
            [buttonType]="'icon-link'"
            [id]="'prompt_interface_reply_btn'"
            (click)="resendPrompt(message)"
            class="action-btn reply"
        ></app-button>
    </div>
    }

    <div class="timestamp">{{ getMessageDate(message.created_at) }}</div>
    <mat-divider [inset]="false"></mat-divider>
</ng-template>