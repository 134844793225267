import { Environment } from "src/app/common/interfaces/environment";

export const environment: Environment = {
  production: true,
  endpoints: {
    assistant: {uri: 'https://api.rndzvs.app/api/assistant'},
    bookmarks: {uri: 'https://api.rndzvs.app/api/bookmarks'},
    contacts: {uri: 'https://api.rndzvs.app/api/contacts'},
    forgotPassword: {
        sendCode: {uri: 'https://api.rndzvs.app/api/users/send-password-reset-code'},
        validateCode: {uri: 'https://api.rndzvs.app/api/users/validate-password-reset-code'},
        resetPassword: {uri: 'https://api.rndzvs.app/api/users/reset-password'},
    },
    geo: {uri: 'https://api.rndzvs.app/api/geospatial'},
    login: {uri: 'https://api.rndzvs.app/api/users/login'},
    meetings: {uri: 'https://api.rndzvs.app/api/meetings'},
    place: {uri: 'https://api.rndzvs.app/api/place'},
    register: {uri: 'https://api.rndzvs.app/api/users/register'},
    search: {uri: 'https://api.rndzvs.app/api/places',},
    sockets: {uri: 'https://api.rndzvs.app'},
    trips: {uri: 'https://api.rndzvs.app/api/trips',},
    waitlist: {uri: 'https://api.rndzvs.app/api/waitlist',},
  },
  recaptcha: {
    siteKey: '6LdbGPwpAAAAACe6rHdsnVtgFjRT687GFqq_Smw_',
  },
  sentry: {
    dsn: "https://af639e18a8c747a0753192803033aea0@o4507098200801280.ingest.us.sentry.io/4507098205847552",
    // Capture Replay for 5% of all sessions,
    replaysSessionSampleRate: 0.05,
    // plus for 50% of sessions with an error
    replaysOnErrorSampleRate: 0.5,
    tracePropagationTargets:['api.rndzvs.app','https://api.rndzvs.app'],
    tracesSampleRate: 0.25,
  }
};
