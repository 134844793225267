import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { ApiResponse } from '../interfaces/api-response';
import { environment } from 'src/environments/environment';
import { AssistantChat } from '../interfaces/assistant';

@Injectable({
  providedIn: 'root'
})
export class AssistantService {

  constructor(private http: HttpClient) { }

  createPrompt(prompt: string, type = 'assistant', lastMsgId: string, data: any = {}): Observable<ApiResponse<AssistantChat[]>> {
    return this.http.post<ApiResponse<AssistantChat[]>>(environment.endpoints.assistant.uri + '/prompt', {prompt, type, lastMsgId, ...data})
  }

  initializeAssistant(type:string, data:any = {}): Observable<ApiResponse<AssistantChat[]>> {
    return new Observable(subscriber => {
      this.http.post(environment.endpoints.assistant.uri + '/initialize-assistant', {data, type}, {
        observe: 'events',
        responseType: 'text',
        reportProgress: true,
      }).subscribe({
        next: event => this.processStream(event, subscriber),
        error: (error) => {
          subscriber.error(error);
        }
      });
    });
  }

  private processData(rawData: string, subscriber: Subscriber<ApiResponse<AssistantChat[]>>) {
    try {
      let data: ApiResponse<AssistantChat[]>;
      data = JSON.parse(rawData);
      subscriber.next(data);
    } catch (error: any) {
      // Handle data processing errors that are not JSON parse errors.
      if(!error.stack?.includes('SyntaxError')) {
        console.error('Error processing streamed data', error);
        subscriber.error('Error processing streamed data');
      }
    }
  }

  private processStream(event: any, subscriber: Subscriber<ApiResponse<AssistantChat[]>>) {
    switch(event?.type) {
      case HttpEventType.Sent:
      break;
      case HttpEventType.UploadProgress:
      break;
      case HttpEventType.ResponseHeader:
        console.log(event); // @todo: Check for 200 response, else handle errors.
      break;
      case HttpEventType.DownloadProgress:
        console.log(event); // @todo: Get partial messages. To be implemented upstream.
      break;
      case HttpEventType.Response:
        this.processData(event.body as string, subscriber);
      break;
      case HttpEventType.User:
        console.log(event); // @todo: Get partial messages. To be implemented upstream.
      break;
    }
  }
}
