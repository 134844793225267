<div *ngIf="hasOptions" class="accessibility place-widget place-options-widget">
    <span class="title">Wheelchair Accessibility</span>
    <ul class="options">
        <li *ngIf="options?.wheelchairAccessibleEntrance as boolean" class="option">
            <span class="material-symbols-outlined">meeting_room</span> 
            <span>Accessible Entrance</span>
        </li>
        <li *ngIf="options?.wheelchairAccessibleParking as boolean" class="option">
            <span class="material-symbols-outlined">local_parking</span> 
            <span>Accessible Parking</span>
        </li>
        <li *ngIf="options?.wheelchairAccessibleRestroom as boolean" class="option">
            <span class="material-symbols-outlined">wc</span> 
            <span>Accessible Restroom</span>
        </li>
        <li *ngIf="options?.wheelchairAccessibleSeating as boolean" class="option">
            <span class="material-symbols-outlined">event_seat</span> 
            <span>Accessible Seating</span>
        </li>
    </ul>
</div>