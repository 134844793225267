import * as moment from 'moment';
import { Component, Input, OnInit } from '@angular/core';
import { EVChargeOptions } from 'src/app/common/interfaces/place';
import { ConnectorAggregationTypeName } from 'src/app/common/enums/place';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-place-ev-charging',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './place-ev-charging.component.html',
  styleUrl: './place-ev-charging.component.scss'
})
export class PlaceEvChargingComponent implements OnInit {

  @Input() data: EVChargeOptions = {};

  available = false;
  availableConnectors = 0;
  lastUpdated:any = null;
  outOfServiceConnectors = 0;
  connectors:any[] = [];
  status = 'UNAVAILABLE';
  time = this.getTime();

  getDate(time: moment.Moment) {
    const timeSincEpocInMilli = time.valueOf();
    switch (true) {
      case timeSincEpocInMilli > this.time.fiveMinsAgo:
        return moment.duration(moment(timeSincEpocInMilli).diff(moment())).humanize(true);

      case timeSincEpocInMilli >= this.time.yesterday:
        return moment(timeSincEpocInMilli).format('h:mm A');

      case timeSincEpocInMilli >= this.time.twoDaysAgo:
        return 'Yesterday, ' + moment(timeSincEpocInMilli).format('h:mm A');

      case timeSincEpocInMilli >= this.time.lastYear:
        return moment(timeSincEpocInMilli).format('Do MMM YYYY, h:mm A');

      default:
        return moment(timeSincEpocInMilli).format('Do MMM, h:mm A');
    }
  }

  ngOnInit(): void {
    if(this.data) {
      this.data.connectorAggregation?.map(connector => {
        this.availableConnectors+= connector.availableCount || 0;
        this.outOfServiceConnectors+= connector.outOfServiceCount || 0;

        if(connector.type) {
          this.connectors.push({
            available: connector.availableCount || 0,
            count: connector.count || 0,
            lastUpdated: connector.availabilityLastUpdateTime || '',
            maxChargeRate: connector.maxChargeRateKw || 0,
            outOfService: connector.outOfServiceCount,
            type: ConnectorAggregationTypeName[connector.type],
          });
        }

        if(connector.availabilityLastUpdateTime) {
          if(!this.lastUpdated || this.lastUpdated?.isAfter(connector.availabilityLastUpdateTime)) {
            this.lastUpdated = moment(connector.availabilityLastUpdateTime);
          }
        }
      });

      this.available = !!this.availableConnectors;
      if(this.available) {
        this.status = 'AVAILABLE';
      } else if(this.data.connectorCount && this.outOfServiceConnectors != this.data.connectorCount) {
        this.status = 'FULL';
      }
    }
  }

  round(num: number, decimalPlaces = 0) {
    var p = Math.pow(10, decimalPlaces);
    var n = (num * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
  }

  private getTime() {
    return {
      now: moment.now(),
      fiveMinsAgo: moment().subtract(5, 'minutes').valueOf(),
      lastYear:  moment().startOf('year').valueOf(),
      twoDaysAgo: moment().startOf('day').subtract(1, 'day').valueOf(),
      yesterday: moment().startOf('day').valueOf(),
    };
  }
}
