import { Injectable, inject } from '@angular/core';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class SpeechService {

  private snackbar = inject(SnackBarService);
  private recognition: any;
  private transcript: string = '';

  constructor() {
    this.snackbar.duration = 2000;
  }

  private alert(str: string) {
    this.snackbar.show(str);
  }

  startListening(): Promise<{transcript: string, _end?: Event}> {
    const _this = this;
    this.transcript = '';
    return new Promise((resolve, reject) => {
      if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
        /* @ts-ignore */
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        this.recognition = new SpeechRecognition();
        this.recognition.continuous = false;
        this.recognition.interimresults = false;
        this.recognition.lang = 'en-US';
        this.recognition.start();
        this.recognition.onresult = (e: any) => {
          console.log(e);
          this.transcript = e.results[0][0].transcript;
          _this.recognition.stop();
          resolve({transcript: this.transcript});
        };
        this.recognition.onend = (_end: Event) => {
          resolve({transcript: this.transcript, _end});
        };
        this.recognition.onerror = (_error: Event) => {
          reject(_error);
        };
      } else {
        const reason = 'Your browser does not support voice recognition!';
        this.alert(reason);
        reject(reason);
      }
    });
  }

  stopListening() {
    if(this.recognition) {
      this.recognition.stop();
    }
  }
}
