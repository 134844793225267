import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AccessibilityOptions } from 'src/app/common/interfaces/place';

@Component({
  selector: 'app-place-accessibility',
  standalone: true,
  imports: [NgIf],
  templateUrl: './place-accessibility.component.html',
  styleUrl: './place-accessibility.component.scss'
})
export class PlaceAccessibilityComponent implements OnInit {
  @Input() options: AccessibilityOptions | undefined;
  hasOptions = false;

  ngOnInit(): void {
    this.hasOptions = !!this.options;
  }
}
