import { CdkMenuModule } from '@angular/cdk/menu';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Component, Input, OnInit, inject } from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Contact } from '../../../../../common/interfaces/contacts';
import { DynamicBgColorDirective } from 'src/app/common/directives/dynamic-bg-color.directive';
import { ButtonComponent } from "../../../../../components/button/button.component";
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-list-contacts',
  standalone: true,
  templateUrl: './list-contacts.component.html',
  styleUrl: './list-contacts.component.scss',
  imports: [
    ButtonComponent,
    CdkMenuModule,
    DynamicBgColorDirective,
    MatDivider,
    NgIf,
    NgTemplateOutlet,
    RouterModule,
    ScrollingModule,
  ]
})
export class ListContactsComponent implements OnInit {

  router = inject(Router);

  @Input() contacts?: Contact[] = [];
  @Input() showLabel = true;
  @Input() labelIdOverride?: string|any = null;
  @Input() virtualScrollThreshold: number = 10;
  labelId = 'contacts-list-label';

  getBgColorSeeder(contact: Contact) {
    let source = contact.firstName;
    if(contact.lastName) {
      (source+' '+contact.lastName).trim();
    }
    return source || contact.email;
  }

  getLetter(contact: Contact) {
    const source = contact.firstName || contact.lastName || contact.email;
    return source.trim().charAt(0);
  }

  goToAddPlan(contact: Contact) {
    this.router.navigate( ['/users', 'planner', 'add'], {state: { contact }});
  }

  ngOnInit(): void {
    this.labelId = this.labelIdOverride || this.labelId;
    if(!this.showLabel) {this.labelId = '';}
  }

  trim(str: string) {
    return str.trim();
  }
}
