import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Meeting, NewMeetingRequest, NewMeetingResponse } from '../interfaces/meeting';
import { ApiResponse } from '../interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  constructor(private http: HttpClient) {}

  async create(request: NewMeetingRequest) {
    return this.http.post<ApiResponse<NewMeetingResponse>>(environment.endpoints.meetings.uri+'/', request);
  }

  find(ids: string[]) {
    return this.http.post<ApiResponse<Meeting[]>>(environment.endpoints.meetings.uri+'/find', { ids })
  }

  list() {
    return this.http.get<ApiResponse<Meeting[]>>(environment.endpoints.meetings.uri+'/');
  }
}
