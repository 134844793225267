export enum BusinessStatus {
    BUSINESS_STATUS_UNSPECIFIED="BUSINESS_STATUS_UNSPECIFIED",
    OPERATIONAL="OPERATIONAL",
    CLOSED_TEMPORARILY="CLOSED_TEMPORARILY",
    CLOSED_PERMANENTLY="CLOSED_PERMANENTLY",
}

export enum BusinessStatusName {
    BUSINESS_STATUS_UNSPECIFIED="UNSPECIFIED STATUS",
    OPERATIONAL="OPERATIONAL",
    CLOSED_TEMPORARILY="TEMPORARILY CLOSED",
    CLOSED_PERMANENTLY="PERMANENTLY CLOSED",
}

export type BusinessStatusNameType = typeof BusinessStatusName;

export type BusinessStatusNameKey = keyof BusinessStatusNameType;

export enum ConnectorAggregationType {
    EV_CONNECTOR_TYPE_UNSPECIFIED = 'EV_CONNECTOR_TYPE_UNSPECIFIED',
    EV_CONNECTOR_TYPE_OTHER = 'EV_CONNECTOR_TYPE_OTHER',
    EV_CONNECTOR_TYPE_J1772 = 'EV_CONNECTOR_TYPE_J1772',
    EV_CONNECTOR_TYPE_TYPE_2 = 'EV_CONNECTOR_TYPE_TYPE_2',
    EV_CONNECTOR_TYPE_CHADEMO = 'EV_CONNECTOR_TYPE_CHADEMO',
    EV_CONNECTOR_TYPE_CCS_COMBO_1 = 'EV_CONNECTOR_TYPE_CCS_COMBO_1',
    EV_CONNECTOR_TYPE_CCS_COMBO_2 = 'EV_CONNECTOR_TYPE_CCS_COMBO_2',
    EV_CONNECTOR_TYPE_TESLA = 'EV_CONNECTOR_TYPE_TESLA',
    EV_CONNECTOR_TYPE_UNSPECIFIED_GB_T = 'EV_CONNECTOR_TYPE_UNSPECIFIED_GB_T',
    EV_CONNECTOR_TYPE_UNSPECIFIED_WALL_OUTLET = 'EV_CONNECTOR_TYPE_UNSPECIFIED_WALL_OUTLET',
}

export enum ConnectorAggregationTypeName {
    EV_CONNECTOR_TYPE_UNSPECIFIED = 'UNSPECIFIED',
    EV_CONNECTOR_TYPE_OTHER = 'OTHER',
    EV_CONNECTOR_TYPE_J1772 = 'J1772',
    EV_CONNECTOR_TYPE_TYPE_2 = 'TYPE 2',
    EV_CONNECTOR_TYPE_CHADEMO = 'CHADEMO',
    EV_CONNECTOR_TYPE_CCS_COMBO_1 = 'CCS COMBO 1',
    EV_CONNECTOR_TYPE_CCS_COMBO_2 = 'CCS COMBO 2',
    EV_CONNECTOR_TYPE_TESLA = 'TESLA',
    EV_CONNECTOR_TYPE_UNSPECIFIED_GB_T = 'GB T',
    EV_CONNECTOR_TYPE_UNSPECIFIED_WALL_OUTLET = 'WALL OUTLET',
}

export enum FuelType {
    FUEL_TYPE_UNSPECIFIED = 'FUEL_TYPE_UNSPECIFIED',
    DIESEL = 'DIESEL',
    REGULAR_UNLEADED = 'REGULAR_UNLEADED',
    MIDGRADE = 'MIDGRADE',
    PREMIUM = 'PREMIUM',
    SP91 = 'SP91',
    SP91_E10 = 'SP91_E10',
    SP92 = 'SP92',
    SP95 = 'SP95',
    SP95_E10 = 'SP95_E10',
    SP98 = 'SP98',
    SP99 = 'SP99',
    SP100 = 'SP100',
    LPG = 'LPG',
    E80 = 'E80',
    E85 = 'E85',
    METHANE = 'METHANE',
    BIO_DIESEL = 'BIO_DIESEL',
    TRUCK_DIESEL = 'TRUCK_DIESEL',
}

export enum PriceLevel {
    PRICE_LEVEL_UNSPECIFIED='PRICE_LEVEL_UNSPECIFIED',
    PRICE_LEVEL_FREE='PRICE_LEVEL_FREE',
    PRICE_LEVEL_INEXPENSIVE='PRICE_LEVEL_INEXPENSIVE',
    PRICE_LEVEL_MODERATE='PRICE_LEVEL_MODERATE',
    PRICE_LEVEL_EXPENSIVE='PRICE_LEVEL_EXPENSIVE',
    PRICE_LEVEL_VERY_EXPENSIVE='PRICE_LEVEL_VERY_EXPENSIVE'
}

export enum SecondaryHoursType {
    SECONDARY_HOURS_TYPE_UNSPECIFIED='SECONDARY_HOURS_TYPE_UNSPECIFIED',
    DRIVE_THROUGH='DRIVE_THROUGH',
    HAPPY_HOUR='HAPPY_HOUR',
    DELIVERY='DELIVERY',
    TAKEOUT='TAKEOUT',
    KITCHEN='KITCHEN',
    BREAKFAST='BREAKFAST',
    LUNCH='LUNCH',
    DINNER='DINNER',
    BRUNCH='BRUNCH',
    PICKUP='PICKUP',
    ACCESS='ACCESS',
    SENIOR_HOURS='SENIOR_HOURS',
    ONLINE_SERVICE_HOURS='ONLINE_SERVICE_HOURS',
}

export enum PlaceTypeIcon {
    amusement_center = "strategy",
    amusement_park = "attractions",
    bicycle_store = "pedal_bike",
    book_store = "auto_stories",
    convenience_store = "local_convenience_store",
    discount_store = "savings",
    grocery_store = "grocery",
    historical_landmark = "things_to_do",
    marina = "sailing",
    airport = "connecting_airports",
    electric_vehicle_charging_station = "ev_station",
    gas_station = "local_gas_station",
    rest_stop = "rest_stop",
    art_gallery = "palette",
    museum = "museum",
    performing_arts_theater = "theater_comedy",
    library = "local_library",
    university = "school",
    aquarium = "home_pin",
    bowling_alley = "home_pin",
    community_center = "communities",
    dog_park = "pets",
    hiking_area = "hiking",
    movie_theater = "theaters",
    park = "park",
    bank = "savings",
    cafe = "local_cafe",
    coffee_shop = "coffee",
    restaurant = "restaurant",
    fire_station = "fire_truck",
    pet_store = "pet_supplies",
    police = "local_police",
    post_office = "local_post_office",
    spa = "spa",
    bed_and_breakfast = "night_shelter",
    hotel = "hotel",
    motel = "night_shelter",
    night_club = "nightlife",
    bar = "local_bar",
    church = "church",
    hindu_temple = "temple_hindu",
    mosque = "mosque",
    synagogue = "synagogue",
    shopping_mall = "local_mall",
    sporting_goods_store = "sports_tennis",
    supermarket = "store",
    fitness_center = "fitness_center",
    golf_course = "golf_course",
    gym = "fitness_center",
    playground = "bedroom_baby",
    swimming_pool = "pool",
    tourist_attraction = "attractions"
}

export type PlaceTypeIconType = typeof PlaceTypeIcon;

export type PlaceTypeIconKeyType = keyof PlaceTypeIconType;

export enum PlaceTypeName {
    amusement_center = 'Amusement Center',
    amusement_park = 'Amusement Park',
    historical_landmark = 'Historical Landmark',
    marina = 'Marina',
    airport = "Airport",
    electric_vehicle_charging_station = "Electric vehicle charging station",
    gas_station = "Gas Station",
    rest_stop = "Rest Stop",
    art_gallery = "Art Gallery",
    museum = "Museum",
    performing_arts_theater = "Performing Arts Theater",
    library = "Library",
    university = "University",
    aquarium = "Aquarium",
    bowling_alley = "Bowling Alley",
    community_center = "Community Center",
    dog_park = "Dog Park",
    hiking_area = "Hiking Area",
    movie_theater = "Movie Theater",
    park = "Park",
    bank = "Bank",
    cafe = "Cafe",
    coffee_shop = "Coffee Shop",
    restaurant = "Restaurant",
    fire_station = "Fire Station",
    police = "Police",
    post_office = "Post Office",
    spa = "Spa",
    bed_and_breakfast = "Bed & Breakfast",
    hotel = "Hotel",
    motel = "Motel",
    night_club = "Night Club",
    bar = "Bar",
    church = "Church",
    hindu_temple = "Hindu Temple",
    mosque = "Mosque",
    synagogue = "Synagogue",
    shopping_mall = "Shopping Mall",
    fitness_center = "Fitness Center",
    golf_course = "Golf Course",
    gym = "Gym",
    playground = "Playground",
    swimming_pool = "Swimming Pool",
    tourist_attraction = "Tourist Attraction",
}

export type PlaceTypeNameType = typeof PlaceTypeName;

export type PlaceTypeNameKeyType = keyof PlaceTypeNameType;

export enum SecondaryPlaceTypeIcon {
    bakery = "bakery_dining",
    fast_food_restaurant = "fastfood",
    hamburger_restaurant = "lunch_dining",
    ice_cream_shop = "icecream",
    italian_restaurant = "dinner_dining",
    japanese_restaurant = "bento",
    meal_takeaway = "takeout_dining",
    pizza_restaurant = "local_pizza",
    ramen_restaurant = "dinner_dining",
    sandwich_shop = "lunch_dining",
    steak_house = "flatware",
    store = "store",
    storefront = "storefront",
    seafood_restaurant = "set_meal",
    sushi_restaurant = "set_meal",
    thai_restaurant = "ramen_dining",
    vietnamese_restaurant = "ramen_dining",
}

