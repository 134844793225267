import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import { AppMode } from '../enums/app';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class AppModeService {

  private stateService = inject(StateService);
  readonly appMode$ = this.stateService.state$.pipe(map((state) => state.appMode));

  setMode(mode: AppMode) {
    this.stateService.setState({appMode: mode,});
  }

  toggleMode() {

    const mode = this.stateService.getState().appMode == AppMode.groups ? AppMode.solo : AppMode.groups;
    this.setMode(mode);
  }
}
