import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppMode } from '../enums/app';
import { ApplicationState } from '../interfaces/application-state';
import { Place } from '../interfaces/place';
import { LookupResult } from '../interfaces/search';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private defaultState: ApplicationState = {
    appMode: AppMode.groups,
    auth: {
        activeSession: false,
        token: null as unknown as undefined,
    },
    lookup: {
        lastSearchKey: null as unknown as undefined,
        places: new Map<string, Place>(),
        results: new Map<string, LookupResult>(),
        searchData: {
            additionalLocations: null,
            organizerLocation: null,
            type: null as unknown as undefined,
        }
    },
    viewport: { classes: [], size: ''}
  };

  private initialState!: ApplicationState;
  private readonly store$!: BehaviorSubject<ApplicationState>;
  readonly state$!: Observable<ApplicationState>;

  constructor(private storage: StorageService) {

    const dehydratedState = this.storage.get('state') as ApplicationState;
    this.initialState = dehydratedState || this.defaultState;
    this.store$ = new BehaviorSubject<ApplicationState>(this.initialState);
    this.state$ = this.store$.pipe(map((state) => state));

    // Automatically save state to storage on state changes.
    this.state$.subscribe(state => {
      this.storage.save('state', state);
    });
  }

  getState(type?: string): ApplicationState {
    return type ? this.store$.value : this.store$.value;
  }

  getStore(): BehaviorSubject<ApplicationState> {
    return this.store$;
  }

  setState(state: ApplicationState, overwrite: boolean = false) {
    if(!overwrite) {
      const currentState = this.store$.value;
      state = this.updateStateObject({...currentState}, state);
    }
    this.store$.next(state);
  }

  updateStateObject(target:  {[key:string]:any}, source: {[key:string]:any}) {

    if(typeof source == 'object' && !Array.isArray(source)) {
        let keys = Object.keys(source);
        keys.forEach(key => {

            const value = source[key];
            if(value === null) {
                delete target[key];
                return;
            }

            if(['number', 'string', 'boolean',].includes(typeof value) || Array.isArray(value)) {
                target[key] = value;
                return;
            }

            if(source[key] instanceof Object) {
                if(!target[key] || 'object' != typeof target[key] || Array.isArray(target[key])) {
                    target[key] = {};
                }
                target[key] = this.updateStateObject(target[key], source[key]);
            }
        });
    }

    return target;
  }
}
