import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterLink } from '@angular/router';
import { PageIcon } from 'src/app/common/interfaces/pages';
import { ButtonColor, ButtonElement, ButtonType } from 'src/app/common/enums/button';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatTooltip, 
    NgClass,
    NgIf,
    NgTemplateOutlet,
    RouterLink,
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent implements OnInit {
  /** The `href` property of the button if rendered as an anchor (`<a></a>`) element. */
  @Input() anchorHref?: any;
  @Input() anchorTarget?: '_blank' | '_parent' | '_self' | '_top';
  @Input() text?: string;
  @Input() ariaLabel?: string;
  @Input() buttonType?: ButtonType | 'mat-fab' | 'mat-fab extended' | 'mat-flat-button' | 'mat-icon-button' 
    | 'mat-mini-fab' | 'mat-raised-button'| 'mat-stroked-button' | 'tooltip-button' | 'icon-link' | 'basic' ;
  @Input() color?: ButtonColor | 'accent' | 'basic' | 'primary' | 'warn';
  /** `0` for anchor. `1` for button. */
  @Input() elementType?: ButtonElement | 0 | 1;
  @Input() disabled?: any;
  @Input() icon?: string|PageIcon;
  @Input() outlinedIcons = false;
  /** The `matTooltip` property of the button element. */
  @Input() tooltip?: string;
  @Input() type?: 'submit' | 'reset' | 'button';
  @Input() iconClass?:string = "";
  /** Custom class to apply to the internal button element. */
  @Input() className?: string = "";

  public btnType!: any;
  hasIconBadge = false;

  /**
   * Callback function for toggling menus
   */
  @Output() click = new EventEmitter();


  constructor() {}

  ngOnInit(): void {
    if(!this.buttonType) {this.buttonType = ButtonType.basic;}
    if(!this.type) {this.type = 'button';}
    if(!this.elementType && this.elementType !== 0) {this.elementType = ButtonElement.button;}
    if(undefined == this.disabled) {this.disabled = false;}
    this.btnType = {
      basic: this.buttonType == (ButtonType.basic || 'basic'),
      fab: this.buttonType == ButtonType.fab,
      fab_extended: this.buttonType == ButtonType.fab_extended,
      flat: this.buttonType == ButtonType.flat,
      icon: this.buttonType == ButtonType.icon,
      icon_link: this.buttonType == 'icon-link',
      mini_fab: this.buttonType == ButtonType.mini_fab,
      raised: this.buttonType == ButtonType.raised,
      stroked: this.buttonType == ButtonType.stroked,
      tooltip: this.buttonType == 'tooltip-button',
    };
    this.iconClass+= this.outlinedIcons ? ' outlined ' : '';
    this.color =  this.color ?? ButtonColor.basic;
    if(this.icon) {
      if('object' == typeof this.icon && this.icon.badge) {
        this.hasIconBadge = true;
      }
    }
  }

  isString = (arg:any) => typeof arg == 'string';

  onClick(event: Event) {
    event.stopImmediatePropagation();
    this.click.emit(event);
  }
}
