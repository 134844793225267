import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NoticeComponent } from 'src/app/components/notice/notice.component';
import { ErrorMessageService, CustomError } from './error-message.service';
import { Observable } from 'rxjs';
import { ApiResponse } from '../interfaces/api-response';
import * as _ from 'lodash';

export enum AjaxFormActions {
  markErrorFields,
  resetAllFields,
}

export type AjaxUiNoticeType = "error"|"info"|"warning";

export interface AjaxUIComponent {
  form: FormGroup<any>;
  formFailedRequestAction?: AjaxFormActions;
  notice:NoticeComponent;
  noticeMessage: string;
  noticeType: 'error'|'info'|'warning';
  requestIsActive: boolean;
  showNoticeMessage: boolean;
  initForm(): void;
  showNotice(notice: string, type:AjaxUiNoticeType): void;
  submitForm(fields: any): Observable<ApiResponse<any>> | void;
}

export interface AjaxButtonState {
  /** The state of the button when a request is active. */
  active?: string;
  /** The state of the button when a request has completed. */
  complete?: string;
  /** The default state of the button. */
  default: string;
  /** The state of the button when a request results in an error. */
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AjaxUxHandlerService {

  /** Whether the form passes validation and no othe previous request is still active  */
  static canSubmitForm(_this: AjaxUIComponent) {
    return _this.form?.status == 'VALID' && !_this.requestIsActive;
  }

  /**
   * Hides the currently active/visible notice for a component.
   * @param _this The component to update.
   */
  static hideNotice(_this: AjaxUIComponent) {
    _this.showNoticeMessage = false;
  }

  /** Callback for a request resulting in an error. Sets component's failed request state. */
  static onError(error: any, _this: AjaxUIComponent) {
    AjaxUxHandlerService.onRequestEnded(_this, error);
  }

  /** Callback for an active request. Sets component's active request state. */
  static onRequestActive(_this: AjaxUIComponent, resetFormValues = false) {
    _this.requestIsActive = true;
    if(resetFormValues) {
      _this?.form?.reset();
    }
  }

  /** Callback that updates component state after requests are completed. */
  static onRequestEnded(_this: AjaxUIComponent, error?:CustomError) {
    _this.requestIsActive = false;
    if(error) {
      if(!_.isUndefined(_this.formFailedRequestAction)) {
        if(_this.formFailedRequestAction == AjaxFormActions.resetAllFields) {
          _this?.form?.reset();
        } else if (_this.formFailedRequestAction == AjaxFormActions.markErrorFields) {
          AjaxUxHandlerService.setFormFieldCustomErrors(_this, error)
        }
      }
      AjaxUxHandlerService.setNotice(_this, 'Oops! ' + ErrorMessageService.extract(error), 'error');
    }
  }

  static setFormFieldCustomErrors(_this: AjaxUIComponent, error: any) {
    let _error = error?.error || error;
    let details = _error?.error?.details;
    let code = _error?.error?.code || _error?.status;
    if(details?.length && 'VALIDATION_ERROR' == code) {
      for (let index = 0; index < details.length; index++) {
        const element = details[index];
        const field = element.path;
        const control = _this.form.controls[field];
        control?.setErrors({custom: true});
      }
    }
  }

  /** Callback that handles notifications for invalid form submissions. */
  static onInvalidFormSubmission(_this: AjaxUIComponent) {
    if(_this.form.status == 'INVALID') {
      _this.form.markAllAsTouched();
      AjaxUxHandlerService.setNotice(_this, 'Please correct invalid fields to continue.','error');
    }
  }

  /**
   * Sets a notice message in the component.
   * @param _this The component to update
   * @param notice The content of the notice to set.
   * @param type The type of notice. One of `error`, `info`, or `warning`. Default: `info`.
   * @param showNotices Whether to render notices. Default: `true`.
   */
  static setNotice(_this: AjaxUIComponent, notice: string, type: 'error'|'info'|'warning' = 'info', showNotices = true) {
    _this.noticeMessage = notice;
    _this.noticeType = type;
    if(showNotices) {
      AjaxUxHandlerService.showNotice(_this);
    }
  }

  /**
   * Displays the currently active notice for a component.
   * @param _this The component to update.
   */
  static showNotice(_this: AjaxUIComponent) {
    _this.showNoticeMessage = true;
    _this.notice?.open();
  }
}
