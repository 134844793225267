import { Component, Input, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { SessionService } from 'src/app/common/services/session.service';
import { CopilotComponent } from '../copilot/copilot.component';
import { MatDialog } from '@angular/material/dialog';
import { ThemeSelectorComponent } from "../theme-selector/theme-selector.component";

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: true,
    imports: [
      NgClass,
      NgIf,
      RouterLinkActive,
      RouterLink,
      ThemeSelectorComponent,
    ]
})
export class NavigationComponent  {
  isLoggedIn = false;

  @Input()
  viewportClass!: string|string[]|Set<string>|{ [klass: string]: any; };

  readonly dialog = inject(MatDialog);

  constructor(private sessionService: SessionService) {
    this.sessionService.isLoggedIn$.subscribe(loggedIn => {
      this.isLoggedIn = !!loggedIn;
    });
  }

  showCopilot() {
    this.dialog.open(CopilotComponent, {
      closeOnNavigation: true,
      height: '100%',
      width: '100%',
    });
  }
}
