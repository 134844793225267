import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  static readonly alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  static readonly alphaNumeric = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  static readonly numeric = '0123456789';
  static readonly uppercaseAlpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  static readonly lowercaseAlpha = 'abcdefghijklmnopqrstuvwxyz';

  constructor() { }

  static getRandomAlphaNumeric(config:{
    caseSensitive?: boolean,
    length:number,
  }={
    caseSensitive: false,
    length:16,
  }): string {
    const letters = config?.caseSensitive ? CryptoService.alphaNumeric : (CryptoService.uppercaseAlpha + CryptoService.numeric);
    let value = '';
    for (let i = 0; i < (config?.length || 16); i++) {
      value += letters[Math.floor(Math.random() * 16)];
    }
    return value;
  }

  static getTimestamp() {
    return new Date().getTime();
  }
}
