<div *ngIf="hasOptions" class="contact-details place-widget place-options-widget">
    <div class="title">Contact Details</div>
    <ul class="options">
        <li *ngIf="address" class="option address">
            <span class="material-symbols-outlined">home_pin</span>
            <div>
                <span class="address-text">{{ address }}</span>
                <a *ngIf="directionsUrl" href="{{ directionsUrl }}" class="directions link" target="_blank">Get Directions
                    <span class="icon material-symbols-outlined sup">
                        open_in_new
                    </span>
                </a>
            </div>
        </li>
        <li *ngIf="phone" class="option phone">
            <span class="material-symbols-outlined">call</span>
            <a href="tel:{{phone}}" class="link">{{ phone }}</a>
        </li>
        <li *ngIf="plusCode" class="option plus_code">
            <span class="material-symbols-outlined">pin_drop</span> {{ plusCode }}
        </li>
        <li *ngIf="websiteUrl" class="option">
            <span class="material-symbols-outlined">public</span>
            <a href="{{ websiteUrl }}" class="link" target="_blank">
                Visit Website
                <span class="icon material-symbols-outlined sup">
                    open_in_new
                </span>
            </a>
        </li>
    </ul>
</div>