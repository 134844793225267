import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PaymentOptions } from 'src/app/common/interfaces/place';

@Component({
  selector: 'app-place-payment',
  standalone: true,
  imports: [NgIf],
  templateUrl: './place-payment.component.html',
  styleUrl: './place-payment.component.scss'
})
export class PlacePaymentComponent implements OnInit {
  @Input() options: PaymentOptions | undefined;
  hasOptions = false;

  ngOnInit(): void {
    this.hasOptions = !!this.options &&
      (
        this.options.acceptsCashOnly as boolean ||
        this.options.acceptsCreditCards as boolean ||
        this.options.acceptsDebitCards as boolean ||
        this.options.acceptsNfc as boolean
      );
  }
}
