import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { SessionService } from '../services/session.service';

export const UnauthorizedErrorInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
  const session = inject(SessionService);
  return next(req).pipe(
    catchError(response => {
      if('HttpErrorResponse' == response?.name && response?.status && -1 != [401, 403].indexOf(response.status)) { // If unauthorized or forbidden HTTP response code from server
        console.log(response?.statusText + " Server Response Intercepted");
        session.onExpiredSession();
      }
      return throwError(() => response);
    })
  );
};
