<div class="trips-list-container">

    @if (showFilter) {
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input 
            matInput
            [formControl]="filterControl"
            (keyup)="applyFilter($event)"
            placeholder="Ex. New York"
            #input
        >
    </mat-form-field>
    }

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z1">

        <ng-container matColumnDef="details">
            <th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>

                @if (showTableHeaderTitle) {
                <span>You have {{dataSource.data.length || 0}} trip{{1 == dataSource.data.length ? '' : 's'}}.</span>
                }

                @if(showCreateTripButton) {
                <app-button
                    anchorHref="/trips/planner"
                    anchorTarget="_self"
                    icon="airplane_ticket"
                    text="Plan A New Trip"
                    tooltip="Plan A New Trip"
                    buttonType="icon-link"
                ></app-button>
                }
            </th>
            <td mat-cell *matCellDef="let trip">

                <div class="header header-photo-bg trip-card-header">
                    <div class="trip">
                        <a href="/trips/planner/{{trip.id || trip._id}}" routerLink="/trips/planner/{{trip.id || trip._id}}" class="link" target="_self">{{ trip.title }}</a>
                    </div>
                </div>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let place; columns: columnsToDisplay;" class="trip-place-row"></tr>

        <tr class="mat-row" *matNoDataRow class="no-results-row">
            <td class="mat-cell no-results-column" [attr.colspan]="columnsToDisplay.length">
            @if (!dataSource.data.length) {
                You don't have any planned trips. <a class="link" routerLink="/trips/planner">Click here to plan a new trip</a> 🎉.
            } @else {
                No trips were found matching the filter <em>"{{filterValue}}"</em>
            }
            </td>
        </tr>
    </table>

    @if (showPaginator) {
    <mat-paginator #paginator
        [length]="dataSource.data.length"
        [pageSize]="paginatorOptions.pageSize || 5"
        [pageSizeOptions]="paginatorOptions.pageSizeOptions || [5, 10, 25, 100]"
        aria-label="Select number of trips to show per page"
    ></mat-paginator>
    }
</div>