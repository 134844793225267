import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { map } from 'rxjs';
import { SocketErrors as Errors, SocketEvents as Events } from '../enums/events';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private client!: Socket;

  constructor(private session: SessionService) {}

  connect(auth: any) {
    this.client = new Socket(this.makeConfig({auth}));

    this.client.connect((error) => {
      console.log('Socket connection error: ', error);
    });

    this.registerListener(Events.connect_error, (err: any) => {
      if (-1 !== [Errors.invalid_username, Errors.client_credentials_expired].indexOf(err.message)) {
        console.log('Session expired.');
        this.session.onExpiredSession(err?.data);
      } else if('timeout' == err?.message) {
        console.log('Handle socket connection timeout');
      } else {
        console.log('Socket connection error:', err);
      }
    });

    this.client.onAny((event, ...args) => {
      console.log(`Event: ${event}:`, args);
    });
  }

  disconnect() {
    if(this.client && this?.client?.ioSocket?.connected) {
      this.client.disconnect();
    }
  }

  emitEvent(event:string, payload:any) {
    return this.client.emit(event, payload);
  }

  makeConfig(_options?: SocketIoConfig["options"]): SocketIoConfig {
    return {
      url: environment.endpoints.sockets.uri,
      options: {
        autoConnect: true,
        reconnectionAttempts: 30,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 60000,
        ..._options
      }
    };
  }

  observeEvent(event: string) {
    return this.client.fromEvent(event).pipe(map((data:any) => data?.msg));
  }

  registerListener(event: string, listener:any, once=false) {
    return once ?
      this.client.once(event, listener) :
      this.client.on(event, listener);
  }

  unregisterAllListeners(event?: string) {
    return this.client.removeAllListeners(event);
  }

  unregisterListener(event: string, listener: any) {
    return this.client.off(event, listener);
  }
}
