import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: 'app-notice',
  standalone: true,
  imports: [MatIcon, NgIf, ButtonComponent],
  templateUrl: './notice.component.html',
  styleUrl: './notice.component.scss',
  animations: [
    trigger('fadeOut', [
      state('visible', style({
        opacity: 1
      })),
      state('invisible', style({
        opacity: 0,
        height: 0
      })),
      transition('visible => invisible', animate('1s'))
    ])
  ]
})
export class NoticeComponent implements OnInit {
  /** Whether to automatically close the notice once `duration` elapses. */
  @Input() autoClose = true;
  /** Notice duration (in milliseconds.) Default: `5000`. Set to 0 for a notice that does not auto close.*/
  @Input() duration = 5000;
  /** Type of notice to render: Options: 'info' | 'warning' | 'error'. Default: '`info`' */
  @Input() type: 'alert' | 'info' | 'warning' | 'error' = 'info';
  /** Whether to show the close button in the notice. */
  @Input() showCloseButton = true;
  /** The notice message to display */
  @Input() message = '';
  /** The notice icon to display, otherwise default icon for notice type is rendered. */
  @Input() icon = '';
  /** Whether to allow content injection. If true provide an projected innerHTML template. */
  @Input()contentProjection = false;
  /** The notice presentation style: Options: 'toast' | 'banner'. Default: 'toast' */
  @Input() presentation: 'toast' | 'banner' = 'toast';

  /** Event emitter for when the notice is closed. */
  @Output() onClosed = new EventEmitter;
  /** Event emitter for when the notice is opened. */
  @Output() onOpened = new EventEmitter;

  protected visibility = 'visible'; // Initial state
  customClass = '';
  
  /** Close the notice. */
  close(duration = 0) {
    this.duration = duration;
    setTimeout(() => {
      this.visibility = 'invisible';
      this.onClosed.emit();
    }, this.duration); // Close after duration
  }

  ngOnInit(): void {
    this.setupIcon();
    this.setupCssClass();
    this.scheduleClose();
  }

  open() {
    this.visibility = 'visible';
    this.onOpened.emit();
    this.scheduleClose();
  }

  private setupCssClass() {
    let classes = [];
    if(this.presentation === 'banner') {
      classes.push('main-notification');
    }
    switch (this.type) {
      case 'alert':
        classes.push('is-alert');
        break;
      case 'warning':
        classes.push('is-info');
        break;
      case 'error':
        classes.push('is-warning');
        break;
      default:
        classes.push('is-primary');
    }
    this.customClass = classes.join(' ');
  }

  private setupIcon() {
    if(this.icon) {return;}
    switch (this.type) {
      case 'warning':
        this.icon = 'report';
      break;
      case 'error':
        this.icon = 'warning';
      break;
      default:
        this.icon = 'info';
    }
  }

  private scheduleClose() {
    if(this.duration && this.autoClose) {
      this.close(this.duration);
    }
  }
}
