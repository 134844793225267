<div *ngIf="hasPriceLevel" [ngClass]="getPriceClass()" class="price-level place-widget place-options-widget">
    <div *ngIf="showTitle" class="title">Payment Options</div>
    <ul class="options">
        <li class="option price">
            <span class="material-symbols-outlined one">universal_currency_alt</span>
            <span *ngIf="showText">Inexpensive</span>
        </li>
        <li class="option price">
            <span class="material-symbols-outlined two">universal_currency_alt</span>
            <span *ngIf="showText">Moderate</span>
        </li>
        <li class="option price">
            <span class="material-symbols-outlined three">universal_currency_alt</span>
            <span *ngIf="showText">Expensive</span>
        </li>
        <li class="option price">
            <span class="material-symbols-outlined four">universal_currency_alt</span>
            <span *ngIf="showText">Very Expensive</span>
        </li>
    </ul>
</div>