<div class="place-list" [ngClass]="viewportClasses">
    <div class="results-status center-contents-horizontally">
        <mat-icon>lists</mat-icon>
        <span class="results-count">Showing {{ places?.length }} result{{ places?.length == 1 ? '' : 's'}}</span>
    </div>
    <app-place
        class="place-item"
        *ngFor="let place of places"
        [place]="place"
        (onBookmarkPlace)="onBookmarkPlace(place)"
        (onSchedulePlace)="onSchedulePlace(place)"
    ></app-place>
</div>