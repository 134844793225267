<div class="copilot">
    <div class="close-btn-container">
        <app-button [buttonType]="'mat-fab'" class="close-btn" icon="close" (click)="closeModal()"></app-button>
    </div>
    <div class="title">
        My Rendezvous CoPilot&trade;
    </div>
    @if(isLoggedIn) {
    <app-prompt-interface [autosize]="true" class="prompt-interface-container"></app-prompt-interface>
    } @else {
    <div class="login-cta">
        Please <a (click)="onLoginRequest()">LOGIN</a> to continue using your Rendezvous Copilot...
    </div>
    }
</div>