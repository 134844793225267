@if(object && objectsLoaded) {
    @if('place' == object.type || 'placeList' == object.type) {
        @if(placesLoaded) {
        <ng-container [ngTemplateOutlet]="placeListTemplate" [ngTemplateOutletContext]="{object}"></ng-container>
        }
    } @else if('contact' == object.type || 'contactList' == object.type) {
        @if(contactsLoaded) {
        <ng-container [ngTemplateOutlet]="contactListTemplate" [ngTemplateOutletContext]="{object}"></ng-container>
        }
    } @else if('meeting' == object.type || 'meetingList' == object.type) {
        @if(meetingsLoaded) {
        <ng-container [ngTemplateOutlet]="meetingListTemplate" [ngTemplateOutletContext]="{object}"></ng-container>
        }
    } @else if('trip' == object.type || 'tripList' == object.type) {
        @if(tripsLoaded) {
        <ng-container [ngTemplateOutlet]="tripListTemplate" [ngTemplateOutletContext]="{object}"></ng-container>
        }
    } @else if('status' == object.type) {
        <ng-container [ngTemplateOutlet]="statusTemplate" [ngTemplateOutletContext]="{object}"></ng-container>
    }
} @else if (object && objectsLoadFailed) {
    <p class="center-contents-horizontally">
        <mat-icon class="error">error</mat-icon> &nbsp; 
        This {{ getObjectTypeName(object.type) }} cannot be loaded at this time.
    </p>
} @else if (object && !objectsLoaded && !objectsLoading) {
    <p class="center-contents-horizontally">
        <mat-icon class="error">error</mat-icon> &nbsp; 
        Object type {{ object.type }} not yet implemented.
    </p>
} @else if (object && objectsLoading) {
    <p class="center-contents-horizontally">
        <mat-icon class="loading rotate360">autorenew</mat-icon> &nbsp; 
        Loading {{ getObjectTypeName(object.type) }}...
    </p>
} @else {
    <p class="center-contents-horizontally">
        <mat-icon class="error">error</mat-icon> &nbsp; 
        No object to render.
    </p>
}

<ng-template #placeListTemplate let-object="object">
    <app-place-list [places]="places"></app-place-list>
</ng-template>

<ng-template #contactListTemplate let-object="object">
    <app-list-contacts [contacts]="contacts" [showLabel]="false"></app-list-contacts>
</ng-template>

<ng-template #meetingListTemplate let-object="object">
    <app-list-meetings></app-list-meetings>
</ng-template>

<ng-template #tripListTemplate let-trip="trip">
    <app-list-trips
        [paginatorOptions]="{pageSize: 10, pageSizeOptions: [5, 10, 20, 50]}"
        [showFilter]="trips && trips.length > 10"
        [showCreateTripButton]="true"
        [showPaginator]="trips && trips.length > 10"
        [showTableHeaderTitle]="false"
        [trips]="trips"
    ></app-list-trips>
</ng-template>

<ng-template #statusTemplate let-object="object">
    <div class="status center-contents-horizontally">
        <mat-icon class="loading rotate360">autorenew</mat-icon> &nbsp; 
        <span class="status_text">{{ status || (object?.content?.length ? object.content[0] : 'Processing request...') }}</span>
    </div>
</ng-template>