<div class="ev-charging place-options-widget">
    <span class="title">EV Charger Details</span>
    <ul class="statuses">
        <li class="status">
            <mat-icon class="icon">charger</mat-icon>
            <span class="status-icon {{ status.toLowerCase() }}"></span>
            <span class="value {{ status.toLowerCase() }}">{{ status }}</span>
        </li>
        <li class="status">
            <mat-icon class="icon">ev_charger</mat-icon>
            <span class="value {{ status.toLowerCase() }}">{{ availableConnectors }}/{{ data.connectorCount }}</span>
        </li>
        @if(lastUpdated){
        <li class="status">
            <mat-icon class="icon">update</mat-icon>
            <span class="value updated">{{ getDate(lastUpdated) }}</span>
        </li>
        }
    </ul>
    <ul class="options">
        @for (connector of connectors; track $index) {
        <li class="
            option 
            {{connector.available ? 'available' : ''}} 
            {{connector.outOfService == connector.count ? 'oos' : ''}} 
            ">
            <!-- <mat-icon class="icon">ev_charger</mat-icon> -->
            <span class="description">{{ connector.type }} {{ connector.maxChargeRate ? round(connector.maxChargeRate, 2) + 'kw' : '' }} - &nbsp;</span>
            <span class="availability">({{ connector.available }}/{{ connector.count }})</span>
        </li>
        }
    </ul>
</div>