import { NgForOf, } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  standalone: true,
  imports: [NgForOf,],
  templateUrl: './rating.component.html',
  styleUrl: './rating.component.scss'
})
export class RatingComponent implements OnInit {
  @Input() /** The number of votes the rating is based on */ ratingCount: number = 1;
  @Input() /** The value of the rating */ ratingValue: number = 0;
  @Input() /** The value of the rating */ maxRating: number = 5; // Assume scale of 1-5/

  ratingPartialStars!: number;
  ratingFullStars!: number;
  ratingEmptyStars!: number;

  ngOnInit(): void {
    this.ratingPartialStars = Math.ceil(this.ratingValue % 1);
    this.ratingFullStars = Math.floor(this.ratingValue);
    this.ratingEmptyStars = this.maxRating - this.ratingFullStars - this.ratingPartialStars;
  }
}
