<div class="place">
    <div
        class="header header-photo-bg"
        [style.backgroundImage]="'url('+placeImage.photoUri+')'"
    >

        <!-- SCHEDULE BUTTON WIDGET -->
        @if (render.showSchedulerBtn) {
        <app-button
            class="schedule child-inherits-color"
            [buttonType]="card.scheduleLinkType"
            [color]="card.bookmarkLinkColor"
            [icon]="'schedule_send'"
            [iconClass]="'inherit-color'"
            [outlinedIcons]="true"
            (click)="schedulePlace($event)"
            ariaLabel="'Plan Rendezvous At This Place' Button"
            matTooltip="Plan a Rendezvous Here!"
        ></app-button>
        }

        <!-- BOOKMARK BUTTON WIDGET -->
        @if (render.showBookmarkBtn) {
        <app-button
            class="bookmark child-inherits-color {{bookmarked ? 'bookmarked' : ''}}"
            [buttonType]="card.bookmarkLinkType"
            [color]="card.bookmarkLinkColor"
            [icon]="bookmarked ? 'bookmark_remove' : 'bookmark_add'"
            [iconClass]="bookmarked ? 'black' : 'inherit-color'"
            [outlinedIcons]="true"
            (click)="!bookmarked ? bookmarkPlace() : unbookmarkPlace()"
            [ariaLabel]="bookmarked ? 'Remove This Place From My Bookmarks?' : 'Bookmark This Place'"
            [matTooltip]="bookmarked ? 'Remove Bookmark' : 'Bookmark This Location'"
        ></app-button>
        }

        @if (render.showTypeWidget && render.showTypeWidgetAboveTitle) {
        <div class="place-type-widget">
            <app-place-icon class="place-type-icon" type="{{place.primaryType}}"></app-place-icon>
            <span class="place-type-name">{{ place.primaryTypeDisplayName?.text }}</span>
        </div>
        }

        <!-- PLACE TITLE -->
        <div class="title">
            <a *ngIf="!isDetailedView" href="/place/{{place.id || place._id}}" routerLink="/place/{{place.id || place._id}}" class="link" target="_blank">{{ place.displayName?.text }}</a>
            <span *ngIf="isDetailedView" class="link">{{ place.displayName?.text }}</span>
            <app-place-status class="place-status-widget"
                [status]="place.businessStatus"
            ></app-place-status>
        </div>

        <!-- PLACE TYPE WIDGET -->
        @if (render.showTypeWidget && !render.showTypeWidgetAboveTitle) {
        <div class="place-type-widget">
            <app-place-icon class="place-type-icon" type="{{place.primaryType}}"></app-place-icon>
            <span class="place-type-name">{{ place.primaryTypeDisplayName?.text }}</span>
        </div>
        }

        <!-- PRICE LEVEL WIDGET -->
        @if (render.showPriceLevelsWidget) {
        <app-place-pricing class="place-pricing-widget"
            [priceLevel]="place.priceLevel"
        ></app-place-pricing>
        }

        <!-- RATINGS WIDGET -->
        @if (render.showRatingWidget) {
        <app-rating class="ratings" *ngIf="place.rating" [ratingValue]="place.rating || 0" [ratingCount]="place.userRatingCount || 1"></app-rating>
        }

        @if (render.showImageAttribution) {
        <div *ngIf="this.placeImage?.displayName" class="attribution">
            Photo by {{ this.placeImage.displayName }}
        </div>
        }

        <!-- HEADER OVERLAY LAYER -->
        <div class="header-bg-overlay"></div>
    </div>
    <div class="content">

        <!-- PLACE DESCRIPTION/SUMMARY -->
        @if (render.showEditorialSummary) {
        <p class="summary">{{place.editorialSummary?.text}}</p>
        }

        <!-- EV CHARGING DETAILS WIDGET -->
         @if (render.showEvChargeOptionsWidget && place.evChargeOptions) {
            <app-place-ev-charging [data]="place.evChargeOptions"></app-place-ev-charging>
         }

        <!-- OPEN TIMES/SCHEDULE WIDGET -->
         @if (render.showScheduleWidget) {
        <app-place-schedule
            [currentOpeningHours]="place.currentOpeningHours"
            [regularOpeningHours]="place.regularOpeningHours"
        ></app-place-schedule>
        }

        <!-- DINING OPTIONS WIDGET -->
         @if (render.showDiningWidget) {
        <app-place-dining
            [curbsidePickup]="place.curbsidePickup"
            [delivery]="place.delivery"
            [dineIn]="place.dineIn"
            [menuForChildren] ="place.menuForChildren"
            [reservable] ="place.reservable"
            [servesBeer] ="place.servesBeer"
            [servesBreakfast] ="place.servesBreakfast"
            [servesBrunch] ="place.servesBrunch"
            [servesCocktails] ="place.servesCocktails"
            [servesCoffee] ="place.servesCoffee"
            [servesDessert] ="place.servesDessert"
            [servesDinner] ="place.servesDinner"
            [servesLunch] ="place.servesLunch"
            [servesVegetarianFood] ="place.servesVegetarianFood"
            [servesWine] ="place.servesWine"
            [takeout] ="place.takeout"
        ></app-place-dining>
        }

        <!-- ACCESSIBILITY OPTIONS WIDGET -->
         @if (render.showAccessibilityWidget) {
        <app-place-accessibility
            [options]="place.accessibilityOptions"></app-place-accessibility>
        }

        <!-- PARKING OPTIONS WIDGET -->
         @if (render.showParkingWidget) {
        <app-place-parking
            [options]="place.parkingOptions" ></app-place-parking>
        }

        <!-- PAYMENT OPTIONS WIDGET -->
         @if (render.showPaymentWidget) {
        <app-place-payment
            [options]="place.paymentOptions"></app-place-payment>
        }

        <!-- VIBE WIDGET -->
         @if (render.showVibeWidget) {
        <app-place-vibe
         [allowsDogs]="place.allowsDogs"
         [goodForChildren]="place.goodForChildren"
         [goodForGroups]="place.goodForGroups"
         [goodForWatchingSports]="place.goodForWatchingSports"
         [liveMusic]="place.liveMusic"
         [outdoorSeating]="place.outdoorSeating"
        ></app-place-vibe>
        }

        <!-- PLACE CONTACT DETAILS WIDGET -->
         @if (render.showContactWidget) {
        <app-place-contact
            [address]="place.formattedAddress"
            [directionsUrl]="place.googleMapsUri"
            [phone]="place.internationalPhoneNumber || place.nationalPhoneNumber"
            [websiteUrl]="place.websiteUri"
        ></app-place-contact>
        }
    </div>
    <div class="actions"></div>
</div>