<div class="meetings-list-container">
    <label *ngIf="showLabel" class="meetings-list--label" id="meetings-list-label">
        Meetings
    </label>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input 
            matInput
            [formControl]="filterControl"
            (keyup)="applyFilter($event)"
            placeholder="Ex. Mia"
            #input
        >
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8">
        @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
            <th mat-header-cell *matHeaderCellDef mat-sort> {{getColumnName(column)}} </th>
            @switch (column) {
                @case ('participants') {
                <td mat-cell *matCellDef="let element">
                    <div class="avatar-list">
                        @for (participant of element[column]; track participant) {
                        <div class="avatar" [dynamicBgColor]="participant?.user?._id">{{ getInitials(participant?.user) }}</div>
                    }
                    </div>
                </td>
                }
                @case ('scheduledTime') {
                <td mat-cell *matCellDef="let element">
                    <span class="scheduled-at">{{getMeetingDate(element[column])}}</span>
                </td>
                }
                @case ('status') {
                <td mat-cell *matCellDef="let element">
                    <span class="status {{element[column]}}">{{getStatusName(element[column])}}</span>
                </td>
                }
                @case ('venue') {
                <td mat-cell *matCellDef="let element">
                    <span class="venue"><a [routerLink]="'/place/'+element[column]._id" target="_blank">{{getMeetingVenue(element[column])}}</a></span>
                </td>
                }
                @default {
                <td mat-cell *matCellDef="let element">{{element[column]}} </td>
                }
            }
        </ng-container>
        }
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    @if (expandedElement === element) {
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    } @else {
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    }
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="meeting-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                    <mat-divider></mat-divider>

                    @for (column of columnsToDisplay; track column) {
                    <div class="{{ element[column] }} icon-widget meeting-element-detail-row">
                    @switch (column) {
                        @case ('participants') {
                        <!-- check whether to render participants here or manually below -->
                        @if(showParticpantsColInMainView) {
                        <mat-icon>perm_contact_calendar</mat-icon>
                        <div class="metadata">
                            <div class="cell-title title">Participants</div>
                            <div class="cell-content particpants">
                                @for (participant of element[column]; track participant) {    
                                <div class="participant">
                                    <span class="avatar" [dynamicBgColor]="participant?.user?._id">{{ getInitials(participant?.user) }}</span>
                                    <span>{{ getParticipantName(participant?.user) }} </span>
                                </div>
                                }
                            </div>
                        </div>
                        }
                        }
                        @case ('scheduledTime') {
                        <mat-icon>event</mat-icon>
                        <div class="metadata">
                            <span class="title">Scheduled For</span>
                            <span class="scheduled-at">{{getMeetingDate(element[column], true)}}</span>
                        </div>
                        }
                        @case ('status') {
                        <mat-icon>work_history</mat-icon>
                        <div class="metadata">
                            <div class="title">Status</div>
                            <span class="status {{element[column]}}">{{element[column]}}</span>
                        </div>
                        }
                        @case ('venue') {
                        <mat-icon>place</mat-icon>
                        <div class="metadata">
                            <div class="title">Place</div>
                            <span class="venue">{{getMeetingVenue(element[column])}}</span>
                        </div>
                        }
                        @default {
                        <span> {{element[column]}} </span>
                        }
                    }
                    </div>
                    }

                    @if (!showParticpantsColInMainView) {
                    <div class="participants icon-widget meeting-element-detail-row">
                        <mat-icon>perm_contact_calendar</mat-icon>
                        <div class="metadata">
                            <div class="cell-title title">Participants</div>
                            <div class="cell-content particpants">
                                @for (participant of element.participants; track participant) {    
                                <div class="participant">
                                    <span class="avatar" [dynamicBgColor]="participant?.user?._id">{{ getInitials(participant?.user) }}</span>
                                    <span>{{ getParticipantName(participant?.user) }} </span>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="meeting-element-row"
            [class.meeting-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="meeting-detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow class="no-results-row">
            <td class="mat-cell no-results-column" [attr.colspan]="columnsToDisplayWithExpand.length">
            @if (!dataSource.data.length) {
                No Rendezvous were found for you, and the good news is, we can plan some! 🎉. You can <a class="link" routerLink="/users/planner/add/">quickly create one</a> yourself, or head over to <a class="link" routerLink="/dashboard">your dashboard</a> and have your personal AI assistant 🤖 take care of it.
            } @else {
                No Rendezvous were found matching the filter <em>"{{input.value}}</em>"
            }
            </td>
        </tr>
    </table>

    <mat-paginator #paginator
        [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page of users"
    ></mat-paginator>
</div>